import moment from 'moment';

export const toLocalDateTime = (datetime) => {
  if (datetime === null) return moment().format('YYYY-MM-DDTHH:mm');
  return moment(datetime).format('YYYY-MM-DDTHH:mm');
};
export const formatDateToLocal = (isoString) => {
  const date = new Date(isoString);

  // ✅ 로컬 타임존에서 연도, 월, 일 가져오기
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 1월 = 0이므로 +1 필요
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}.${month}.${day}`;
};

export const leftTimeText = (leftTime) => {
  const days = 1;
  const hours = 1;
  const minutes = 1;
  const seconds = 1;
  return `${days}Day ${hours}H ${minutes}M ${seconds}S`;
};

export const calculateTime = (date) => {
  if (date === null || date === undefined) {
    return;
  }
  if (typeof date === 'number') {
    date *= 1000;
  }
  const w_date = new Date(date.valueOf());
  const w_time = w_date.getTime();

  const cur = new Date();
  const c_time = cur.getTime();

  const chai = c_time - w_time;
  if (chai < 1000 * 60) {
    // eslint-disable-next-line consistent-return
    return 'new';
  }
  if (chai < 1000 * 60 * 60) {
    const showNum = Math.floor(chai / (1000 * 60));
    // eslint-disable-next-line consistent-return
    return `${showNum}분 전`;
  }
  if (chai < 1000 * 60 * 60 * 24) {
    const showNum = Math.floor(chai / (1000 * 60 * 60));
    // eslint-disable-next-line consistent-return
    return `${showNum}시간 전`;
  }
  if (chai < 1000 * 60 * 60 * 24 * 30) {
    const showNum = Math.floor(chai / (1000 * 60 * 60 * 24));
    // eslint-disable-next-line consistent-return
    return `${showNum}일 전`;
  }
  if (chai < 1000 * 60 * 60 * 24 * 30 * 12) {
    const showNum = Math.floor(chai / (1000 * 60 * 60 * 24 * 30));
    // eslint-disable-next-line consistent-return
    return `${showNum}달 전`;
  }
};

export const timestampForm = (date) => {
  return `${date.getUTCFullYear()}-${`00${Number(date.getUTCMonth()) + 1}`.slice(-2)}-${`00${date.getUTCDate()}`.slice(
    -2
  )}T${`00${date.getUTCHours()}`.slice(-2)}:${`00${date.getUTCMinutes()}`.slice(-2)}:${`00${date.getUTCSeconds()}`.slice(
    -2
  )}.${`000${date.getUTCMilliseconds()}`.slice(-3)}Z`;
};

export const regularTimestamp = (date) => {
  switch (typeof date) {
    case 'number':
      return moment(date * 1000).format('YYYY.MM.DD A hh:mm');
    default:
      return moment(date).format('YYYY.MM.DD A hh:mm');
  }
};

export const shortTimestamp = (date) => {
  switch (typeof date) {
    case 'number':
      return moment(date * 1000).format('YYYY.MM.DD');
    default:
      return moment(date).format('YYYY.MM.DD');
  }
};

export const CommonTimestamp = (date) => {
  switch (typeof date) {
    case 'number':
      return moment(date * 1000).format('YYYY. MM. DD. HH:mm');
    default:
      return moment(date).format('YYYY. MM. DD. HH:mm');
  }
};

export const formatToLacaleDate = (date) => {
  let form;
  form = moment(date).format('YYYY-MM-DDTHH:mm');
  if (form === 'Invalid date') return '';
  return form;
};

export const defaultDateFormat = (date) => {
  switch (typeof date) {
    case 'number':
      return moment(date * 1000).format('YYYY-MM-DD');
    default:
      return moment(date).format('YYYY-MM-DD');
  }
};
