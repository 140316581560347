import React, { useEffect, useState } from 'react';
import './SNB.scss';
import { TABS_IN_USE } from './tabs';
import TabSection from './organisms/TabSection';
import iconMenu from '../../assets/images/icon_menu.png';
import Logo from '../../assets/images/Logo_EQHub.png';
import { useNavigate } from 'react-router-dom';

const SNB = (props) => {
  const { isOpen, setIsOpen } = props;
  const TABS = TABS_IN_USE;

  const [opendSubMenuList, setOpendSubMenuList] = useState([]);
  const [clickedMenu, setClickedMenu] = useState();
  const [isUserPanelOpen, setIsUserPanelOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userPanelHandleOutsideClose = (e) => {
      if (isUserPanelOpen) setIsUserPanelOpen(false);
    };
    document.addEventListener('click', userPanelHandleOutsideClose);
    return () => document.removeEventListener('click', userPanelHandleOutsideClose);
  }, [isUserPanelOpen]);

  return (
    <div id="SNB">
      {/*<div className="user-menu">*/}
      {/*  <div className="user-menu-info" onClick={() => setIsUserPanelOpen((prev) => !prev)}>*/}
      {/*    {isUserPanelOpen && <UserPanel setIsUserPanelOpen={setIsUserPanelOpen} ref={userPanelRef} />}*/}
      {/*    <div className="user-menu-info-icon">*/}
      {/*      <img src={userIcon} className="user-menu-info-icon-src" alt="" />*/}
      {/*    </div>*/}
      {/*    <div className="user-menu-info-label-wrapper">*/}
      {/*      <div className="user-menu-info-label">*/}
      {/*        <span className="user-menu-info-label-hello">Hello, </span>*/}
      {/*        <span className="user-menu-info-label-name">{`${userInfo.firstName} ${userInfo.lastName}`}</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <Notifications />*/}
      {/*</div>*/}
      {isOpen && <div className="overlay" onClick={() => setIsOpen(false)}></div>}
      <div className={`SNB-content-wrapper${isOpen ? ' open' : ''}`}>
        <div className="main-header-logo">
          <img src={iconMenu} className="main-header-menu" alt="=" onClick={() => setIsOpen(!isOpen)} />
          <img
            src={Logo}
            className="main-header-logo-src"
            alt="logo"
            onClick={() => {
              setIsOpen(!isOpen);
              navigate('/');
            }}
          />
        </div>
        <div className="nav-container">
          {TABS.map((tab) => (
            <TabSection
              tab={tab}
              key={`tab-section-${tab.index}`}
              opendSubMenuList={opendSubMenuList}
              setOpendSubMenuList={setOpendSubMenuList}
              clickedMenu={clickedMenu}
              setIsOpen={setIsOpen}
              setClickedMenu={(e) => {
                setClickedMenu(e);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SNB;
