import './Header.scss';
import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/images/Logo_EQHub.png';
import ProjectSelector from '../side_navigation/organisms/ProjectSelector';
import { useNavigate } from 'react-router-dom';
import UserPanel from './organism/User/UserPanel';
import userIcon from '../../assets/images/service/Icon_SNB_user.png';
import Notifications from './organism/Notification/Notifications';
import { useSelector } from 'react-redux';
import { user_info } from '../../../reducer/userReducer';
import iconMenu from '../../assets/images/icon_menu.png';
import SNB from '../side_navigation/SNB';

const Header = () => {
  const navigate = useNavigate();
  const userPanelRef = useRef<any>(null);
  const [isUserPanelOpen, setIsUserPanelOpen] = useState<boolean>(false);
  const userInfo = useSelector(user_info);
  useEffect(() => {
    const clickUserPanelOutside = (e) => {
      if (isUserPanelOpen && !userPanelRef?.current?.contains(e.target)) {
        setIsUserPanelOpen(false);
      }
    };
    document.addEventListener('click', clickUserPanelOutside);
    return () => document.removeEventListener('click', clickUserPanelOutside);
  }, [isUserPanelOpen]);

  const [isSNBOpen, setIsSNBOpen] = useState<boolean>(false);

  return (
    <header className="main-header">
      <SNB isOpen={isSNBOpen} setIsOpen={setIsSNBOpen} />
      <div className="main-header-logo">
        <img src={iconMenu} className="main-header-menu" alt="=" onClick={() => setIsSNBOpen(!isSNBOpen)} />
        <img
          src={Logo}
          className="main-header-logo-src"
          alt="logo"
          onClick={() => {
            navigate('/');
          }}
        />
      </div>
      <div className="main-header-content">
        <ProjectSelector />
        <div className="user-menu">
          <div className="user-menu-info" onClick={() => setIsUserPanelOpen((prev) => !prev)}>
            {isUserPanelOpen && <UserPanel ref={userPanelRef} />}
            <div className="user-menu-info-icon">
              <img src={userIcon} className="user-menu-info-icon-src" alt="" />
            </div>
            <div className="user-menu-info-label-wrapper">
              <div className="user-menu-info-label">
                <span className="user-menu-info-label-hello">Hello, </span>
                <span className="user-menu-info-label-name">{`${userInfo.firstName} ${userInfo.lastName}`}</span>
              </div>
            </div>
          </div>
          <Notifications />
        </div>
      </div>
    </header>
  );
};

export default Header;

// const MENU_ITEMS = [
//   { id: 1, name: 'Network' },
//   { id: 2, name: 'Kits' },
//   { id: 3, name: 'Tools' },
//   { id: 4, name: 'Project' },
//   { id: 5, name: 'Docs' },
// ];
//
// const SUB_MENU_ITEMS = [
//   { id: 101, name: 'Network', menuId: 1 },
//   { id: 102, name: 'Node', menuId: 1 },
//   { id: 103, name: 'Wallet', menuId: 1 },
//   { id: 104, name: 'B.E', menuId: 1 },
//   { id: 201, name: 'Token Kit', menuId: 2 },
//   { id: 202, name: 'NFT Kit', menuId: 2 },
//   { id: 301, name: '(Deprecated)', menuId: 3 },
//   { id: 302, name: 'PG', menuId: 3 },
//   { id: 303, name: 'Airdrop', menuId: 3 },
//   { id: 304, name: 'Contract-Library', menuId: 3 },
//   { id: 305, name: 'Chain Request', menuId: 3 },
//   { id: 401, name: 'Dashboard', menuId: 4 },
//   { id: 402, name: 'Billing', menuId: 4 },
//   { id: 403, name: 'Setting', menuId: 4 },
//   { id: 501, name: 'User Guide', menuId: 5 },
//   { id: 502, name: 'API Docs', menuId: 5 },
// ];
// const GNB = () => {
//   const [isOpened, setIsOpened] = useState<boolean>(false);
//   const [selectedMenuId, setSelectedMenuId] = useState<number>(0);
//   return (
//     <div className="gnb" onMouseEnter={() => setIsOpened(true)} onMouseLeave={() => setIsOpened(false)}>
//       {MENU_ITEMS.map((item) => (
//         <div className="gnb-item" key={item.id} onMouseEnter={() => setSelectedMenuId(item.id)} onMouseLeave={() => setSelectedMenuId(0)}>
//           {item.name}
//         </div>
//       ))}
//     </div>
//   );
// };
