import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setConnectedInfo } from '../../store/redux/EQconnect/EQConnectSlice';
import BasicButton from 'view/components/_atom/button/BasicButton';
import { ButtonSize, ButtonTheme } from 'view/components/_atom/button/StyledButton';
import './EQConnectWalletButtonWithMetamask.scss';
import { logic } from '../../logic';
import { ConnectedWallet } from '../../view/assets/constant/kit.type';
import ConnectWalletModal from '../../view/service/kits/components/ConnectWalletModal';

type EQConnectWalletButtonWithMetamaskProps = {
  size?: 'small' | 'regular';
  connectedWallet: ConnectedWallet;
  setConnectedWallet: (connectedWallet: ConnectedWallet) => void;
};
const EQConnectWalletButtonWithMetamask = (props: EQConnectWalletButtonWithMetamaskProps) => {
  const { size = 'regular', connectedWallet, setConnectedWallet } = props;
  const { connectAddress: address } = connectedWallet || {};
  const dispatch = useDispatch();
  const isConnected = useMemo(() => Boolean(address), [address]);

  const getAddress = async () => {
    const { result, error } = await logic.wallet.getConnectWalletData();
    if (error) {
      return;
    }
    setConnectedWallet({ ...result, accountId: result?.accountId || undefined });
  };
  useEffect(() => {
    getAddress();
    window.addEventListener('message', getAddress);
    return () => {
      window.removeEventListener('message', getAddress);
    };
  }, []);

  const disconnectWallet = async () => {
    const { error } = await logic.wallet.disconnectWallet();
    if (error) {
      return;
    }
    setConnectedWallet({ connectWalletType: '', connectAddress: '', accountId: undefined });
  };
  useEffect(() => {
    const sessionAddress = sessionStorage.getItem('address');
    const sessionType = sessionStorage.getItem('type');
    dispatch(
      setConnectedInfo({
        address: sessionAddress,
        type: sessionType,
      })
    );
    window.addEventListener('disconnect', () => {
      disconnectWallet();
    });
    return () => {
      window.removeEventListener('disconnect', () => {
        disconnectWallet();
      });
    };
  }, []);

  const [isOpenConnectWalletModal, setIsOpenConnectWalletModal] = useState(false);

  const onClickButton = async () => {
    if (!isConnected) {
      setIsOpenConnectWalletModal(true);
    } else {
      const disconnectEvent = new CustomEvent('disconnect');
      window.dispatchEvent(disconnectEvent);
      await disconnectWallet();
    }
  };

  const connectButtonRenderer = useMemo(() => {
    if (Boolean(address)) {
      return (
        <div className="flex-column">
          <div>Disconnect</div>
          <div style={{ fontSize: 12, color: '#AFAFAF' }}>{`${address.slice(0, 6)}...${address.slice(-5)}`}</div>
        </div>
      );
    } else {
      if (size === 'small') {
        return <div>Connect Wallet</div>;
      }
      return <div>Connect</div>;
    }
  }, [address, size]);

  return (
    <>
      <ConnectWalletModal isOpen={isOpenConnectWalletModal} closeModal={() => setIsOpenConnectWalletModal(false)} setConnectedWallet={setConnectedWallet} />
      {/*<SelectWalletModal isOpen={isOpenSelectWalletModal} closeModal={() => setIsOpenSelectWalletModal(false)} getAddress={getAddress} />*/}
      <BasicButton
        size={size === 'small' ? ButtonSize.DYNAMIC : ButtonSize.LARGE}
        style={size === 'small' ? { height: 40 } : undefined}
        theme={address ? ButtonTheme.SECONDARY : ButtonTheme.PRIMARY}
        onClick={onClickButton}
      >
        {connectButtonRenderer}
      </BasicButton>
    </>
  );
};

export default EQConnectWalletButtonWithMetamask;

// export const enum WalletType {
//   metaMask = 'metamask',
//   eqHub = 'eqhub',
// }
//
// const WALLETS = [
//   {
//     type: WalletType.metaMask,
//     img: metamaskLogo,
//   },
//   {
//     type: WalletType.eqHub,
//     img: eqWalletLogo,
//   },
// ];
// type SelectWalletModalProps = {
//   isOpen: boolean;
//   closeModal: () => void;
//   getAddress: () => void;
// };
// const SelectWalletModal = (props: SelectWalletModalProps) => {
//   const { isOpen, closeModal, getAddress } = props;
//
//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={closeModal}
//       style={customStyles}
//       overlayClassName="modal-overlay-style"
//       ariaHideApp={false}
//       shouldCloseOnOverlayClick={true}
//     >
//       <div className="connect-wallet-modal">
//         <div className="connect-wallet-modal-header">Connect Your Wallet</div>
//         <div className="connect-wallet-modal-content">
//           {WALLETS.map((wallet) => (
//             <div
//               key={wallet.type}
//               className="connect-wallet-modal-content-button"
//               onClick={async () => {
//                 let result: any;
//                 if (wallet.type === WalletType.eqHub) {
//                   result = requestAddressToExtension();
//                 } else {
//                   result = await requestAddressToMetamask(wallet.type);
//                 }
//                 if (result) {
//                   getAddress();
//                 }
//                 closeModal();
//               }}
//             >
//               <img src={wallet.img} alt={wallet.type} className="connect-wallet-modal-content-logo" />
//             </div>
//           ))}
//         </div>
//         <div className="connect-wallet-modal-footer">
//           You don't have a wallet?
//           <a
//             className="connect-wallet-modal-footer-link"
//             href="https://chromewebstore.google.com/detail/eq-hub-wallet/jjkhekbaikcnpnhflnnlnbonkkobkiim?hl=ko&utm_source=ext_sidebar"
//             target="_blank"
//             rel="noreferrer"
//           >
//             Get EQHub wallet
//           </a>
//         </div>
//       </div>
//     </Modal>
//   );
// };
