import React, { useEffect, useMemo, useState } from 'react';
import PageArticle from '../../../components/_template/page/PageArticle';
import KitTab from '../../../components/_atom/tab/KitTab';
import '../../../styles/ManageTokenKit.scss';
import { TokenKit } from '../../../assets/constant/tokenKit.type';
import { logic } from '../../../../logic';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { networkParserNew } from '../../../../utils/Utils';
import DivideLine from '../../../components/_atom/line/DivideLine';
import useLoading from '../../../../hooks/useLoading';
import PageLoading from '../../../components/_atom/loading/PageLoading';
import TableStatus, { TABLE_STATUS } from '../components/TableStatus';
import BasicButton from '../../../components/_atom/button/BasicButton';
import { ButtonSize, ButtonTheme } from '../../../components/_atom/button/StyledButton';
import KitContainer from '../components/KitContainer';
import CopyToClipboard from 'react-copy-to-clipboard/lib';
import toast from 'react-hot-toast';
import iconToggle from '../../../assets/images/icon_upper_arrow_circle.png';
import { statusType } from '../../../../utils/status.utils';
import TransactionStatus from '../../../components/_atom/transactionStatus/TransactionStatus';
import warningIcon from '../../../assets/images/atom/Icon_warning_black.png';
import { Account } from '../../../assets/constant/accountManager.type';
import BasicTable from '../../../components/_atom/table/BasicTable';
import EmptyDataTemplate from '../../dashboard/components/EmptyDataTemplate';
import SelectNetworkModal from '../components/SelectNetworkModal';
import LabelValue from '../components/LabelValue';
import Setting from '../components/Setting';
import { ConnectedWallet, Contract, Contract_Constructor, KitFunction, KitFunctionMethod, KitType } from '../../../assets/constant/kit.type';
import { KIT_TABS } from '../../../assets/constant/kit.constant';
import MethodForm from '../components/MethodForm';
import RegisterAccountModal from '../components/RegisterAccountModal';
import { PagingBar } from '../../../components/_atom/paging/PagingBar';
import { ReactComponent as IconCopy } from '../../../assets/images/icon_copy.svg';
import EnterPinCodeModal from '../components/EnterPinCodeModal';
import ConnectWalletModal from '../components/ConnectWalletModal';
import EQConnectWalletButtonWithMetamask from '../../../../modules/EQConnect/EQConnectWalletButtonWithMetamask';
import { formatDateToLocal } from '../../../../utils/TimeUtils';

const ManageTokenKit = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (Array.from(searchParams.keys()).length > 0) {
      // 쿼리가 존재하는 경우에만 초기화
      setSearchParams({});
    }
  }, [tabIndex]);
  const [isOpenDescription, setIsOpenDescription] = useState(false);
  const params = useParams();
  const { id: kit_id } = params;
  const [kitDetail, setKitDetail] = useState<TokenKit>();
  const [kitType, setKitType] = useState<KitType>();
  const status =
    kitDetail?.status === 4
      ? TABLE_STATUS.DEPLOYED
      : kitDetail?.status === 3
        ? TABLE_STATUS.PROCESSING
        : kitDetail?.status === 2
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  const navigate = useNavigate();
  const getTokenKitDetail = async (kit_id: number) => {
    const { result, error } = await logic.tokenKit.getTokenKitDetail(kit_id);
    if (error) {
      if (error.data === 'not_access') {
        navigate('/');
      }
      return;
    }
    const { result: kitType, error: kitTypeError } = await logic.tokenKit.getTokenKitTypeDetail(result?.version?.kit_type_id);
    if (kitTypeError) {
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const network = networks.find((network) => network.id === result.chain_id);
    const tokenKit: TokenKit = {
      ...result,
      network: network?.label,
    };
    setKitDetail(tokenKit);
    setKitType(kitType);
  };
  const [transactionHash, setTransactionHash] = useState('');

  const { loading } = useLoading({
    dependency: [kit_id],
    synchronousFunction: async () => {
      await getTokenKitDetail(Number(kit_id));
    },
  });
  const { version } = kitDetail || {};

  if (loading) {
    return <PageLoading />;
  }
  return (
    <PageArticle id="ManageTokenKit">
      <div
        style={
          {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
            '--kit-title-wrapper-height':
              kitDetail?.status === 3 && transactionHash ? (isOpenDescription ? '486px' : '356px') : isOpenDescription ? '320px' : '188px',
          } as React.CSSProperties
        }
      >
        <div style={{ position: 'sticky', width: '100%', zIndex: 100, top: 0 }}>
          <div className="kit-title-wrapper" onClick={() => setIsOpenDescription(!isOpenDescription)}>
            <div className="kit-info-wrapper">
              <img src={kitDetail?.image} alt="token image" className={`kit-info-image${isOpenDescription ? ' open' : ''}`} />
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  <div className="kit-info-name">{kitDetail?.name}</div>
                  <img src={iconToggle} alt="toggle" className={`kit-info-toggle${isOpenDescription ? ' open' : ''}`} />
                </div>
                {isOpenDescription && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: 20, marginTop: 10 }}>
                    <div className="kit-info-text">Type : {kitType?.name}</div>
                    <div className="kit-info-text">Version : {version?.version}</div>
                    <TableStatus status={status} />
                  </div>
                )}
              </div>
            </div>
            {isOpenDescription && version?.description && version.description.length > 0 && (
              <>
                <DivideLine style={{ margin: '20px 0' }} />
                <div className="kit-description">{version.description}</div>
              </>
            )}
          </div>
          {kitDetail?.status === 3 && transactionHash && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 50px 20px',
                backgroundColor: '#F9F9F9',
              }}
            >
              <div style={{ width: '768px', marginBottom: -20 }}>
                <TransactionStatus
                  transactionHash={transactionHash}
                  statusDetail={{
                    type: statusType.WAIT,
                    title: 'Smart contract is being deployed.',
                    showTransactionHash: true,
                  }}
                  microChainId={kitDetail?.chain_id as number}
                  type={'small'}
                />
              </div>
            </div>
          )}
          <div className="kit-tab-wrapper">
            <KitTab tabs={KIT_TABS} tabIndex={tabIndex} setTabIndex={setTabIndex} />
          </div>
        </div>
        {tabIndex === 0 && <Dashboard kitDetail={kitDetail!} />}
        {tabIndex === 1 && <Function kitDetail={kitDetail!} />}
        {tabIndex === 2 && <Dev kitDetail={kitDetail!} setKitDetail={setKitDetail} setTransactionHash={setTransactionHash} />}
        {tabIndex === 3 && <Setting kitDetail={kitDetail!} />}
      </div>
    </PageArticle>
  );
};

export default ManageTokenKit;

const Dashboard = ({ kitDetail }: { kitDetail: TokenKit }) => {
  const status =
    kitDetail?.status === 4
      ? TABLE_STATUS.DEPLOYED
      : kitDetail?.status === 3
        ? TABLE_STATUS.PROCESSING
        : kitDetail?.status === 2
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  const datas = [
    { label: 'Kit ID', value: kitDetail?.kit_id },
    { label: 'Kit Name', value: kitDetail?.name },
    {
      label: 'Kit Image',
      value: <img src={kitDetail?.image} alt={''} className={'kit-info-image'} />,
    },
    { label: 'Token Symbol', value: kitDetail?.symbol },
    { label: 'States', value: <TableStatus status={status} /> },
    { label: 'Kit Version', value: kitDetail?.version?.version },
  ];
  return (
    <div className="tab-content-wrapper">
      <KitContainer
        title={'Kit Basic Info'}
        // description="The Setting tab allows users to configure token details, manage access permissions, customize appearance, and optimize operational settings for seamless management."
      >
        {datas.map((data, index) => {
          return <LabelValue key={index} label={data.label} value={data.value} />;
        })}
      </KitContainer>
    </div>
  );
};

const Function = ({ kitDetail }: { kitDetail: TokenKit }) => {
  const [functions, setFunctions] = useState<KitFunction[]>([]);
  const [selectedFunction, setSelectedFunction] = useState<KitFunction>();
  const getFunctions = async (kitId: number) => {
    const { result, error } = await logic.tokenKit.getTokenFunctionList(kitId);
    if (error) {
      return;
    }
    setFunctions(result);
    setSelectedFunction(result[0]);
  };
  const { loading } = useLoading({
    dependency: [kitDetail?.kit_id],
    synchronousFunction: async () => {
      await getFunctions(kitDetail?.kit_id);
    },
  });
  const [functionMethod, setFunctionMethod] = useState<KitFunctionMethod>();
  const getFunctionMethods = async (feature_id: number) => {
    const { result, error } = await logic.tokenKit.getTokenFunctionMethodList(feature_id);
    if (error) {
      return;
    }
    setFunctionMethod(result);
  };
  useEffect(() => {
    if (selectedFunction) {
      getFunctionMethods(selectedFunction.feature_id);
    }
  }, [selectedFunction]);

  if (loading) {
    return <PageLoading />;
  }
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div className="sub-menu-container">
        <div className="sub-menu-wrapper">
          {functions.map((func, index) => (
            <div
              key={index}
              className={`sub-menu-name${selectedFunction?.feature_id === func?.feature_id ? ' selected' : ''}`}
              onClick={() => setSelectedFunction(func)}
            >
              {func.name}
            </div>
          ))}
        </div>
      </div>
      <div className="tab-content-wrapper">
        <div style={{ display: 'flex', width: '100%', gap: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 20 }}>
            {kitDetail?.status !== 4 && (
              <div className="kit-container" style={{ gap: 12 }}>
                <div style={{ display: 'flex', gap: 8 }}>
                  <img src={warningIcon} alt="!" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                  <div className="kit-container-title">Notice : Prerequisite for using functions</div>
                </div>
                <div className="kit-container-description">
                  Please complete the 'Deploy' process in the 'Dev' tab, then use the functions in the 'Function' tab.
                </div>
              </div>
            )}
            <KitContainer title={selectedFunction?.name ?? ''} description={selectedFunction?.description} />
            {functionMethod?.methods.map((method, index) => (
              <MethodForm method={method} selectedFunction={selectedFunction} kitDetail={kitDetail} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const DEV_SUBMENU = [
  { name: 'Contract Manager', id: 0 },
  { name: 'Account Manager', id: 1 },
];
const Dev = ({ kitDetail, setKitDetail, setTransactionHash }: { kitDetail: TokenKit; setKitDetail: any; setTransactionHash: any }) => {
  const [selectedSubmenu, setSelectedSubmenu] = useState<{ name: string; id: number }>(DEV_SUBMENU[0]);
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <div className="sub-menu-container">
        <div className="sub-menu-wrapper">
          {DEV_SUBMENU.map((subMenu, index) => (
            <div key={index} className={`sub-menu-name${selectedSubmenu?.id === subMenu?.id ? ' selected' : ''}`} onClick={() => setSelectedSubmenu(subMenu)}>
              {subMenu.name}
            </div>
          ))}
        </div>
      </div>
      {selectedSubmenu.id === 0 && <ContractManager kitDetail={kitDetail} setKitDetail={setKitDetail} setTransactionHash={setTransactionHash} />}
      {selectedSubmenu.id === 1 && <AccountManager />}
    </div>
  );
};

const ContractManager = ({ kitDetail, setKitDetail, setTransactionHash }: { kitDetail: TokenKit; setKitDetail: any; setTransactionHash: any }) => {
  const status =
    kitDetail?.status === 4
      ? TABLE_STATUS.DEPLOYED
      : kitDetail?.status === 3
        ? TABLE_STATUS.PROCESSING
        : kitDetail?.status === 2
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  const [contractInfo, setContractInfo] = useState<Contract>();
  const contractLibrary = useMemo(() => contractInfo?.contractLibrary, [contractInfo]);
  const [constructor, setConstructor] = useState<Contract_Constructor>();
  const getContractInfo = async () => {
    const { result, error } = await logic.contract.getContractDetail(kitDetail?.contract_id);
    if (error) {
      return;
    }
    setContractInfo(result);
  };
  const { loading } = useLoading({
    dependency: [kitDetail?.contract_id],
    synchronousFunction: async () => {
      await getContractInfo();
    },
  });
  const getContractConstructor = async (contractId: number) => {
    const { result, error } = await logic.contract.getContractConstructor(contractId);
    if (error) {
      return;
    }
    setConstructor(result as Contract_Constructor);
  };
  useEffect(() => {
    if (contractInfo) {
      getContractConstructor(contractInfo.contract_id);
    }
  }, [contractInfo]);

  const [isOpenSelectNetworkModal, setIsOpenSelectNetworkModal] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState<any>();

  const submitKit = async () => {
    const { result, error } = await logic.tokenKit.makeContractInfo(kitDetail?.kit_id, selectedNetwork.id);
    if (error) {
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const network = networks.find((network) => network.id === result.chain_id);
    const tokenKit: TokenKit = {
      ...result,
      network: network?.label,
    };
    setKitDetail(tokenKit);
    return true;
  };

  const [connectedWallet, setConnectedWallet] = useState<ConnectedWallet>({
    connectWalletType: '',
    connectAddress: '',
  });
  const [password, setPassword] = useState<string>('');
  const [isEnterPinCodeModalOpen, setIsEnterPinCodeModalOpen] = useState(false);
  const changeKitStatus = async (kit_id: number) => {
    const { result, error } = await logic.tokenKit.changeTokenKitStatusProcessing(kit_id);
    if (error) {
      return;
    }
    setKitDetail((prev) => ({ ...prev, ...result }));
  };
  const deployKitWithMetamask = async () => {
    const { result: tx_hash, error } = await logic.tokenKit.tokenKitDeployTransactionWithMetamask(kitDetail?.kit_id);
    if (error) {
      toast.error('Failed to deploy kit');
    }
    if (tx_hash) {
      setTransactionHash(tx_hash);
      await changeKitStatus(kitDetail?.kit_id);
    }
  };
  const deployKitWithAccount = async () => {
    const { result, error } = await logic.tokenKit.tokenKitDeployWithServer(kitDetail?.kit_id, password);
    if (error) {
      toast.error('Failed to deploy kit');
    }
    const { transaction_hash } = result || {};
    if (transaction_hash) {
      setTransactionHash(transaction_hash);
    }
    setKitDetail((prev) => ({ ...prev, ...result }));
  };
  const [isOpenConnectWalletModal, setIsOpenConnectWalletModal] = useState(false);
  const deployKit = async () => {
    if (!connectedWallet.connectAddress) {
      setIsOpenConnectWalletModal(true);
      return;
    }
    if (connectedWallet.connectWalletType === 'metamask') {
      await deployKitWithMetamask();
    }
    if (connectedWallet.connectWalletType === 'accountManager') {
      if (!password || password.length !== 6) {
        setIsEnterPinCodeModalOpen(true);
        return;
      }
      await deployKitWithAccount();
    }
  };

  const onClickDeploy = async () => {
    if (status === TABLE_STATUS.CREATED) {
      if (!selectedNetwork) {
        setIsOpenSelectNetworkModal(true);
        return;
      }
      const submitResult = await submitKit();
      if (submitResult) {
        await deployKit();
      }
    }
    if (status === TABLE_STATUS.PREPARED) {
      await deployKit();
    }
  };

  return (
    <div className="tab-content-wrapper">
      <SelectNetworkModal
        selectedNetwork={selectedNetwork}
        setSelectedNetwork={setSelectedNetwork}
        kitDetail={kitDetail}
        isOpen={isOpenSelectNetworkModal}
        closeModal={() => setIsOpenSelectNetworkModal(false)}
      />
      <ConnectWalletModal
        isOpen={isOpenConnectWalletModal}
        closeModal={async (connectedWallet) => {
          setIsOpenConnectWalletModal(false);
          if (connectedWallet?.connectWalletType === 'metamask') {
            await deployKitWithMetamask();
          }
          if (connectedWallet?.connectWalletType === 'accountManager') {
            setIsEnterPinCodeModalOpen(true);
          }
        }}
        setConnectedWallet={setConnectedWallet}
      />
      <EnterPinCodeModal
        isOpen={isEnterPinCodeModalOpen}
        closeModal={async () => {
          if (password.length === 6) {
            await deployKitWithAccount();
          }
          setIsEnterPinCodeModalOpen(false);
        }}
        setPin={setPassword}
        pin={password}
      />
      <KitContainer title={'Network Info'}>
        {status === TABLE_STATUS.CREATED ? (
          <LabelValue
            label={'Network'}
            value={
              <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                {selectedNetwork?.label && <div>{selectedNetwork?.label}</div>}
                <div
                  className="kit-select-network-button"
                  onClick={() => {
                    setIsOpenSelectNetworkModal(true);
                  }}
                >
                  Select Network
                </div>
              </div>
            }
          />
        ) : (
          <LabelValue label={'Network'} value={kitDetail?.network} />
        )}
      </KitContainer>
      <KitContainer title={'Contract Info'}>
        <LabelValue label={'Contract Title'} value={contractLibrary?.contract?.title} isLoading={loading} />
        <LabelValue label={'Contract ID'} value={contractInfo?.contract_id} isLoading={loading} />
        <LabelValue label={'Contract Name'} value={contractInfo?.name} isLoading={loading} />
        <LabelValue label={'Made By'} value={contractLibrary?.project} isLoading={loading} />
        <LabelValue label={'Contract Type'} value={contractLibrary?.contract_type_labeling} isLoading={loading} />
        <LabelValue label={'ERC'} value={contractInfo?.erc_type ? `ERC ${contractInfo?.erc_type}` : ''} isLoading={loading} />
        <LabelValue label={'Description'} value={contractLibrary?.contract?.description} isLoading={loading} />
        <LabelValue
          label={'Contract Address'}
          value={
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
              <div>{contractInfo?.contract_address}</div>
              <CopyToClipboard text={contractInfo?.contract_address} onCopy={() => toast('Copied Address', { icon: '👏' })}>
                <IconCopy style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} className="icon-copy" />
              </CopyToClipboard>
            </div>
          }
          isLoading={loading}
        />
      </KitContainer>
      <KitContainer title={'Version Info'}>
        <LabelValue label={'Contract Version'} value={contractInfo?.origin_version} isLoading={loading} />
        <LabelValue label={'Version Description'} value={contractLibrary?.version_description} isLoading={loading} />
      </KitContainer>
      {constructor && constructor.length > 0 && (
        <KitContainer title={'Constructor'}>
          {constructor.map((item, index) => (
            <LabelValue label={`${item.name} (${item.type})`} value={item.description} key={index} />
          ))}
        </KitContainer>
      )}
      {(status === TABLE_STATUS.CREATED || status === TABLE_STATUS.PREPARED) && (
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
          <EQConnectWalletButtonWithMetamask setConnectedWallet={setConnectedWallet} connectedWallet={connectedWallet} />
          <BasicButton size={ButtonSize.LARGE} onClick={() => onClickDeploy()}>
            Deploy
          </BasicButton>
        </div>
      )}
    </div>
  );
};

const ACCOUNT_COLGROUP = [2.5, 1.5, 3, 1];
const ACCOUNT_HEADERS = [
  { title: 'Account ID', accessor: 'id' },
  { title: 'Account Name', accessor: 'name' },
  { title: 'Account Address', accessor: 'address' },
  { title: 'Created Date', accessor: 'createdAt' },
];
const ACCOUNT_RENDERER = (data: Account, currentKitId: number) => {
  const { service_admin_accounts } = data;
  const isDevManager = service_admin_accounts.some((el) => el.service_identifier === 'TOKEN_KIT' && el.resource_identifier === currentKitId);
  return {
    id: (
      <div style={{ display: 'flex', gap: 10, paddingRight: 10 }}>
        <div
          title={data.account_id}
          style={{
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data.account_id}
        </div>
        <CopyToClipboard text={data.account_id} onCopy={() => toast('Copied ID', { icon: '👏' })}>
          <IconCopy style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} className="icon-copy" />
        </CopyToClipboard>
      </div>
    ),
    name: (
      <div style={{ display: 'flex', gap: 10, paddingRight: 10 }}>
        <div
          title={data.account_id}
          style={{
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data.name}
        </div>
        {isDevManager && (
          <div
            style={{
              padding: '4px 8px',
              borderRadius: 4,
              fontSize: 12,
              fontWeight: 500,
              lineHeight: '14px',
              color: '#5888FF',
              backgroundColor: '#ECEFF1',
            }}
          >
            Manager
          </div>
        )}
      </div>
    ),
    address: (
      <div style={{ display: 'flex', gap: 10, paddingRight: 10 }}>
        <div title={data.address} style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {data.address}
        </div>
        <CopyToClipboard text={data.address} onCopy={() => toast('Copied Address', { icon: '👏' })}>
          <IconCopy style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} className="icon-copy" />
        </CopyToClipboard>
      </div>
    ),
    createdAt: formatDateToLocal(data.created_at),
  };
};

const ACCOUNTS_LIMIT = 10;
const AccountManager = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [maxAccountsNum, setMaxAccountsNum] = useState(0);
  const getAccounts = async (option?: { lastId?: string; offset?: number; order?: 'ASC' | 'DESC' }) => {
    const { result, error } = await logic.accountManager.getAccountManagerList({ limit: ACCOUNTS_LIMIT, ...option });
    if (error) {
      return;
    }
    const { rows: accounts, count: maxAccountsNum } = result;
    setAccounts(accounts);
    if (option?.offset === 0) {
      setMaxAccountsNum(maxAccountsNum);
    }
  };
  const [isOpenRegisterAccountModal, setIsOpenRegisterAccountModal] = useState(false);
  const pageNum = Number(new URLSearchParams(window.location.search).get('account')) || 1;
  const { loading } = useLoading({
    dependency: [pageNum],
    synchronousFunction: async () => {
      await getAccounts({ offset: (pageNum - 1) * ACCOUNTS_LIMIT });
    },
  });

  return (
    <div className="tab-content-wrapper">
      <RegisterAccountModal isOpen={isOpenRegisterAccountModal} closeModal={() => setIsOpenRegisterAccountModal(false)} />
      <KitContainer title={'Account List'}>
        {loading ? (
          <PageLoading style={{ height: 477 }} />
        ) : (
          <>
            {accounts.length === 0 ? (
              <EmptyDataTemplate>
                <div style={{ fontSize: 18, fontWeight: 500, lineHeight: '21px', color: '#7D828D' }}>There are no registered assets yet.</div>
              </EmptyDataTemplate>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 10,
                  width: '100%',
                }}
              >
                <BasicTable data={accounts} colGroup={ACCOUNT_COLGROUP} headers={ACCOUNT_HEADERS} renderer={ACCOUNT_RENDERER} />
                <PagingBar maxPageNum={Math.floor(maxAccountsNum / ACCOUNTS_LIMIT) + 1} query={'account'} />
              </div>
            )}
          </>
        )}
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: 22,
            right: 30,
          }}
        >
          <BasicButton size={ButtonSize.DYNAMIC} theme={ButtonTheme.PRIMARY} onClick={() => setIsOpenRegisterAccountModal(true)}>
            + New Account
          </BasicButton>
        </div>
      </KitContainer>
    </div>
  );
};
