import styled, { css } from 'styled-components';

export enum ButtonTheme {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  MINOR = 'MINOR',
  CREATE = 'CREATE',
  NORMAL = 'NORMAL',
}

export enum ButtonSize {
  SMALL = 'SMALL',
  MIDDLE = 'MIDDLE',
  LARGE = 'LARGE',
  EXTRALARGE = 'EXTRALARGE',
  DYNAMIC = 'DYNAMIC',
  FULL = 'FULL',
  ICON = 'ICON',
}

export enum ButtonShape {
  ROUNDED = 'ROUNDED',
  SQUARE = 'SQUARE',
}

export type StyledButtonProps = {
  theme: ButtonTheme;
  size: ButtonSize;
  shape: ButtonShape;
};

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  transition: background 0.3s;

  // &:hover {
  //   box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  //   transform: translateY(-2px);
  // }

  &:active {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
    transform: translateY(1px);
  }

  &:disabled {
    background-color: #b7babe;
    color: #fff;
    box-shadow: none !important;
    transform: translateY(0px) !important;
    cursor: default;
  }

  ${({ theme }) => THEME_HANDLER(theme)}
  ${({ size }) => SIZE_HANDLER(size)}
    ${({ shape }) => SHAPE_HANDLER(shape)}
`;

const THEME_HANDLER = (theme: ButtonTheme) => {
  switch (theme) {
    case ButtonTheme.PRIMARY:
      return css`
        background-color: #5888ff;
        color: #ffffff;
      `;
    case ButtonTheme.SECONDARY:
      return css`
        border: 1px solid #5888ff;
        background-color: #ffffff;
        color: #5888ff;
      `;
    case ButtonTheme.MINOR:
      return css`
        border: 1px solid #cf7171;
        background-color: #ffffff;
        color: #cf7171;
      `;
    case ButtonTheme.CREATE:
      return css`
        border: none;
        background: linear-gradient(91.68deg, #6773ff 0%, #47a0ff 100%);
        color: #ffffff;

        &:hover {
          background: linear-gradient(91.68deg, #433fff 0%, #3592ff 100%);
        }

        &:active {
          background: linear-gradient(91.68deg, #433fff 0%, #3592ff 100%);
        }
      `;
    case ButtonTheme.NORMAL:
      return css`
        border: 1px solid #d8d8d8;
        background-color: #ffffff;
        color: #7f879e;
      `;
  }
};

const SIZE_HANDLER = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.SMALL:
      return css`
        font-size: 14px;
        height: 40px;
        width: 130px;
      `;
    case ButtonSize.MIDDLE:
      return css`
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        height: 40px;
        width: 200px;
      `;
    case ButtonSize.LARGE:
      return css`
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        height: 48px;
        width: 220px;
      `;
    case ButtonSize.EXTRALARGE:
      return css`
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        height: 48px;
        width: 280px;
      `;
    case ButtonSize.DYNAMIC:
      return css`
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        padding: 8px 14px;
      `;
    case ButtonSize.FULL:
      return css`
        height: 40px;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      `;
    case ButtonSize.ICON:
      return css`
        height: 48px;
        width: 48px;
      `;
  }
};

const SHAPE_HANDLER = (shape: ButtonShape) => {
  switch (shape) {
    case ButtonShape.ROUNDED:
      return css`
        border-radius: 20px;
      `;
    case ButtonShape.SQUARE:
      return css`
        border-radius: 8px;
      `;
  }
};
