import React, { useEffect, useState } from 'react';
import PageArticle from '../../../components/_template/page/PageArticle';
import PageTitle from '../../../components/_molecules/page/PageTitle';
import { ButtonSize, ButtonTheme } from '../../../components/_atom/button/StyledButton';
import BasicButton from '../../../components/_atom/button/BasicButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useLoading from '../../../../hooks/useLoading';
import { useSelector } from 'react-redux';
import { selected_project_id } from '../../../../reducer/projectReducer';
import EmptyListTemplate from '../../../components/_template/emptyList/EmptyListTemplate';
import PageLoading from '../../../components/_atom/loading/PageLoading';
import { addComma, errorAlert, networkParserNew } from '../../../../utils/Utils';
import { getTokenListAction } from '../../../../action/tokenAction';
import { ALL_MAIN_STATUS } from '../../../../constants/commonConstants';
import DivideLine from '../../../components/_atom/line/DivideLine';
import { logic } from '../../../../logic';
import '../../../styles/TokenKitList.scss';
import TableStatus, { TABLE_STATUS } from '../components/TableStatus';
import Modal from 'react-modal';
import { customStyles } from '../../../styles/customStyles';
import Table from '../../../../legacy-common/atom/table/Table';
import Checkbox from '../../../components/_atom/checkbox/Checkbox';
import { PagingBar } from '../../../components/_atom/paging/PagingBar';
import { ReactComponent as DownArrow } from '../../../assets/images/icon_toggle_table_arrow_box_down.svg';
import KitTab from '../../../components/_atom/tab/KitTab';
import { Token, TokenKit } from '../../../assets/constant/tokenKit.type';
import ModalTemplateB from '../../../components/_template/modal/ModalTemplateB';
import createKitImg from '../../../assets/images/img_kit_create.png';
import addKitImg from '../../../assets/images/img_add_public_kit.png';

const TOKEN_KIT_LIMIT = 10;
const TOKEN_KIT_TABS = [
  { label: 'Custom Kit', index: 0 },
  { label: 'Imported Kit', index: 1 },
];

const TokenKitList = () => {
  const projectId = useSelector(selected_project_id);

  const [tabIndex, setTabIndex] = useState(0);
  const [isSelectTokenKitTypeModalOpen, setIsSelectTokenKitTypeModalOpen] = useState(false);
  const [isPublicTokenKitListModalOpen, setIsPublicTokenKitListModalOpen] = useState(false);

  const [tokenKits, setTokenKits] = useState<TokenKit[]>([]);
  const [maxTokenKitsNum, setMaxTokenKitsNum] = useState(0);
  const getTokenKits = async (lastId?: number, order?: 'ASC' | 'DESC') => {
    const { result, error } = await logic.tokenKit.getUserTokenKits({ lastId, limit: TOKEN_KIT_LIMIT, order });
    // const { result, error } = await DUMMY_GET_USER_TOKEN_KITS(offset, TOKEN_KIT_LIMIT, order);

    if (error) {
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const tokenKits = result.rows.map((tokenKit) => {
      const network = networks.find((network) => network.id === tokenKit.chain_id);
      return {
        ...tokenKit,
        network: network?.label,
      };
    });
    setMaxTokenKitsNum(result.count);
    if (lastId && tokenKits.length > 0) {
      setTokenKits((prev) => [...prev, ...tokenKits]);
      return;
    }
    setTokenKits(tokenKits);
  };
  const { loading: tokenKitsLoading } = useLoading({
    dependency: [tabIndex],
    synchronousFunction: async () => {
      if (tabIndex === 0) {
        await getTokenKits();
      }
    },
  });

  const [publicTokenKits, setPublicTokenKits] = useState<TokenKit[]>([]);
  const [maxPublicTokenKitsNum, setMaxPublicTokenKitsNum] = useState(0);
  const getMyPublicTokenKits = async (lastId?: number, order?: 'ASC' | 'DESC') => {
    const { result, error } = await logic.tokenKit.getUserPublicTokenKits({ lastId, limit: TOKEN_KIT_LIMIT, order });
    // const { result, error } = await DUMMY_GET_USER_TOKEN_KITS(offset, TOKEN_KIT_LIMIT, order);

    if (error) {
      errorAlert('Failed to get public token kits. Please try again later.');
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const tokenKits = result.rows.map((tokenKit) => {
      const network = networks.find((network) => network.id === tokenKit.chain_id);
      return {
        ...tokenKit,
        network: network.label,
      };
    });
    setMaxPublicTokenKitsNum(result.count);
    if (lastId && tokenKits.length > 0) {
      setPublicTokenKits((prev) => [...prev, ...tokenKits]);
      return;
    }
    setPublicTokenKits(tokenKits);
  };
  const { loading: publicTokenKitsLoading } = useLoading({
    dependency: [tabIndex],
    synchronousFunction: async () => {
      if (tabIndex === 1) {
        await getMyPublicTokenKits();
      }
    },
  });

  //========= Get Token List =========//
  const [tokenList, setTokenList] = useState<Token[]>([]);
  const getTokenList_ = async () => {
    const { result, error } = await getTokenListAction(
      {
        type: [2],
        mainStatus: ALL_MAIN_STATUS,
        subStatus: [0, 1],
      },
      {
        mainStatus: [5],
      }
    );
    if (result) {
      setTokenList(result);
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };
  const { loading: tokenListLoading } = useLoading({
    dependency: [projectId],
    synchronousFunction: async () => {
      await getTokenList_();
    },
  });

  return (
    <PageArticle id="TokenKitList">
      <SelectMethodToAddKitModal
        isOpen={isSelectTokenKitTypeModalOpen}
        closeModal={() => setIsSelectTokenKitTypeModalOpen(false)}
        setIsPublicTokenKitListModalOpen={setIsPublicTokenKitListModalOpen}
      />
      <PublicKitsModal
        isOpen={isPublicTokenKitListModalOpen}
        closeModal={() => setIsPublicTokenKitListModalOpen(false)}
        getMyPublicTokenKits={getMyPublicTokenKits}
      />
      <PageTitle
        title={'Token Kit List'}
        description={''}
        Button={() => (
          <BasicButton theme={ButtonTheme.CREATE} size={ButtonSize.EXTRALARGE} onClick={() => setIsSelectTokenKitTypeModalOpen(true)}>
            + New Token Kit
          </BasicButton>
        )}
      />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            padding: 40,
            maxWidth: 1240,
          }}
        >
          <div className="kit-title">Token Kit</div>
          <div style={{ marginBottom: 40 }}>
            <KitTab tabs={TOKEN_KIT_TABS} tabIndex={tabIndex} setTabIndex={setTabIndex} />
          </div>
          {tokenKitsLoading || publicTokenKitsLoading ? (
            <PageLoading />
          ) : (
            <>
              {tabIndex === 0 ? (
                <>
                  {tokenKits.length === 0 ? (
                    <EmptyListTemplate description={'No custom token kit yet.'} />
                  ) : (
                    <div className="kit-list">
                      {tokenKits.map((tokenKit) => (
                        <TokenKitCard key={tokenKit.kit_id} tokenKit={tokenKit} />
                      ))}
                      {tokenKits.length < maxTokenKitsNum && (
                        <div className="kit-load-more" onClick={() => getTokenKits(tokenKits[tokenKits.length - 1].kit_id)}>
                          Load More
                          <DownArrow className="kit-load-more-icon" />
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {publicTokenKits.length === 0 ? (
                    <EmptyListTemplate description={'No public token kit yet.'} />
                  ) : (
                    <div className="kit-list">
                      {publicTokenKits.map((tokenKit) => (
                        <TokenKitCard key={tokenKit.kit_id} tokenKit={tokenKit} />
                      ))}
                      {publicTokenKits.length < maxPublicTokenKitsNum && (
                        <div className="kit-load-more" onClick={() => getMyPublicTokenKits(publicTokenKits[publicTokenKits.length - 1].kit_id)}>
                          Load More
                          <DownArrow className="kit-load-more-icon" />
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {tokenList.length > 0 && (
            <>
              {!tokenListLoading && <DivideLine style={{ margin: '40px 0' }} />}
              <div className="kit-title">Token List</div>
              {tokenListLoading ? (
                <PageLoading />
              ) : (
                <div className="kit-list">
                  {tokenList.map((el) => (
                    <TokenCard token={el} key={el.currencyId} />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </PageArticle>
  );
};

export default TokenKitList;

const TokenKitCard = ({ tokenKit }: { tokenKit: TokenKit }) => {
  const navigate = useNavigate();
  const onClickToken = () => {
    navigate(`/application/token-kit/${tokenKit.kit_id}`);
  };
  const status =
    tokenKit.status === 4
      ? TABLE_STATUS.DEPLOYED
      : tokenKit.status === 3
        ? TABLE_STATUS.PROCESSING
        : tokenKit.status === 2
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  return (
    <div className="kit-card" onClick={onClickToken}>
      <img src={tokenKit.image} alt={tokenKit.name} className="kit-card-image" />
      <div className="kit-card-content">
        <div className="kit-card-name">{tokenKit.name}</div>
        <TableStatus status={status} />
      </div>
      <div className="kit-card-content">
        <label className="kit-card-label">Network</label>
        <div className="kit-card-value">{tokenKit.network ?? '-'}</div>
      </div>
      <div className="kit-card-content">
        <label className="kit-card-label">Version</label>
        <div className="kit-card-value">{tokenKit.version.version}</div>
      </div>
    </div>
  );
};

const TokenCard = ({ token }: { token: Token }) => {
  const navigate = useNavigate();
  const onClickToken = () => {
    navigate(`/application/token/manage/${token.currencyId}`);
  };
  const status =
    token.mainStatus === 5
      ? TABLE_STATUS.DEPLOYED
      : token.mainStatus === 4
        ? TABLE_STATUS.PROCESSING
        : token.mainStatus === 3
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  return (
    <div className="token-card" onClick={onClickToken}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={token.image} alt={token.name} className="token-card-image" />
        <div className="token-card-content">
          <div className="token-card-name">{token.name}</div>
          <TableStatus status={status} />
        </div>
      </div>
      <DivideLine style={{ margin: '10px 0' }} />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="token-card-content">
          <label className="token-card-label">Network</label>
          <div className="token-card-value">{token.network}</div>
        </div>
        <div className="token-card-content">
          <label className="token-card-label">Total supply</label>
          <div className="token-card-value">{addComma(token.issued)}</div>
        </div>
        <div className="token-card-content">
          <label className="token-card-label">Symbol</label>
          <div className="token-card-value">{token.symbol}</div>
        </div>
      </div>
    </div>
  );
};

type SelectMethodToAddKitModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  setIsPublicTokenKitListModalOpen: (isOpen: boolean) => void;
};
const TOKEN_KIT_TYPES = [
  {
    type: 'create',
    title: 'Create a new Custom Kit',
    description: 'Create a new custom kit to define and manage your tokens with ease.',
    image: createKitImg,
  },
  {
    type: 'add-public',
    title: 'Add Public Kit',
    description: 'Import a publicly available token kit to integrate into your project.',
    image: addKitImg,
  },
];
const SelectMethodToAddKitModal = (props: SelectMethodToAddKitModalProps) => {
  const { isOpen, closeModal, setIsPublicTokenKitListModalOpen } = props;
  const navigate = useNavigate();
  return (
    <ModalTemplateB
      isOpen={isOpen}
      title={'Add a new Token Kit'}
      description={'Choose the type of token kit you wish to use for your transaction.'}
      showDivideLine={false}
      onRequestClose={closeModal}
    >
      <div className="select-kit-type-modal">
        <div className="select-kit-type-modal-content">
          {TOKEN_KIT_TYPES.map((tokenKitType) => (
            <div
              key={tokenKitType.type}
              className="select-kit-type-modal-content-button"
              onClick={() => {
                if (tokenKitType.type === 'create') {
                  navigate('/application/token-kit/create');
                }
                if (tokenKitType.type === 'add-public') {
                  setIsPublicTokenKitListModalOpen(true);
                }
                closeModal();
              }}
            >
              <img src={tokenKitType.image} alt={tokenKitType.title} className="select-kit-type-modal-content-img" />
              <div className="select-kit-type-modal-content-title">{tokenKitType.title}</div>
              <div className="select-kit-type-modal-content-description">{tokenKitType.description}</div>
            </div>
          ))}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.MIDDLE} onClick={closeModal}>
          Back
        </BasicButton>
      </div>
    </ModalTemplateB>
    // <Modal
    //   isOpen={isOpen}
    //   onRequestClose={closeModal}
    //   style={customStyles}
    //   overlayClassName="modal-overlay-style"
    //   ariaHideApp={false}
    //   shouldCloseOnOverlayClick={true}
    // >
    //   <div className="select-kit-type-modal">
    //     <div className="select-kit-type-modal-header">Add a new Token Kit</div>
    //     <div className="select-kit-type-modal-content">
    //       {TOKEN_KIT_TYPES.map((tokenKitType) => (
    //         <div
    //           key={tokenKitType.type}
    //           className="select-kit-type-modal-content-button"
    //           onClick={() => {
    //             if (tokenKitType.type === 'create') {
    //               navigate('/application/token-kit/create');
    //             }
    //             if (tokenKitType.type === 'add-public') {
    //               setIsPublicTokenKitListModalOpen(true);
    //             }
    //             closeModal();
    //           }}
    //         >
    //           <div className="select-kit-type-modal-content-title">{tokenKitType.label}</div>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </Modal>
  );
};

type PublicKitsModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  getMyPublicTokenKits: (offset?: number, order?: 'ASC' | 'DESC') => Promise<void>;
};
const PUBLIC_KIT_HEADER = [
  { title: ' ', accessor: 'select' },
  { title: ' ', accessor: 'icon' },
  { title: 'Token Kit name', accessor: 'name' },
  { title: 'Network', accessor: 'network' },
  { title: 'Version', accessor: 'version' },
];
const PUBLIC_KIT_COLGROUP = [1, 1, 5.3, 5.3, 5.3].map((el) => ({ style: { width: `${el}%` } }));

const PublicKitsModal = (props: PublicKitsModalProps) => {
  const { isOpen, closeModal, getMyPublicTokenKits } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (Array.from(searchParams.keys()).length > 0) {
      // 쿼리가 존재하는 경우에만 초기화
      setSearchParams({});
    }
  }, [isOpen]);
  const [publicTokenKits, setPublicTokenKits] = useState<TokenKit[]>([]);
  const [maxPublicTokenKitsNum, setMaxPublicTokenKitsNum] = useState(0);

  const getPublicTokenKits = async (offset?: number, order?: 'ASC' | 'DESC') => {
    const { result, error } = await logic.tokenKit.getPublicTokenKits({ offset, limit: TOKEN_KIT_LIMIT, order });
    if (error) {
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const tokenKits = result.rows.map((tokenKit) => {
      const network = networks.find((network) => network.id === tokenKit.chain_id);
      return {
        ...tokenKit,
        network: network.label,
      };
    });
    setPublicTokenKits(tokenKits);
    setMaxPublicTokenKitsNum(result.count);
  };

  const pageNum = Number(new URLSearchParams(window.location.search).get('public-kit')) || 1;
  const { loading: publicKitLoading } = useLoading({
    dependency: [isOpen, pageNum],
    synchronousFunction: async () => {
      if (isOpen) {
        const offset = (pageNum - 1) * TOKEN_KIT_LIMIT;
        await getPublicTokenKits(offset);
      }
    },
  });

  const [selectedKits, setSelectedKits] = useState<TokenKit[]>([]);
  const getSelectedKits = async () => {
    const { result, error } = await logic.tokenKit.getUserPublicTokenKits({});
    if (error) {
      return;
    }
    setSelectedKits(result.rows);
  };
  useEffect(() => {
    getSelectedKits();
  }, []);

  const handleSelectKit = (kit: TokenKit) => {
    const isExist = selectedKits.some((el) => el.kit_id === kit.kit_id);
    if (isExist) {
      setSelectedKits(selectedKits.filter((el) => el.kit_id !== kit.kit_id));
    } else {
      setSelectedKits([...selectedKits, kit]);
    }
  };
  const PUBLIC_KIT_RENDERER = (data: TokenKit) => ({
    select: <Checkbox id={''} label={''} checked={Boolean(selectedKits.find((kit) => kit.kit_id === data.kit_id))} onChange={() => {}} />,
    icon: <img src={data.image} alt={data.name} style={{ width: 40, height: 40, objectFit: 'contain', border: '2px solid #E7E9EF', borderRadius: 20 }} />,
    name: data.name,
    network: data.network,
    version: data.version.version,
  });

  const addSelectedKits = async () => {
    const selectedKitIds = selectedKits.map((kit) => kit.kit_id);
    const { result, error } = await logic.tokenKit.postPublicTokenKits(selectedKitIds);
    if (error) {
      return;
    }
    if (result) {
      await getMyPublicTokenKits();
      closeModal();
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      overlayClassName="modal-overlay-style"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
    >
      <div className="public-kits-modal">
        <div className="public-kits-modal-header">Public Token Kit List</div>
        <div className="public-kits-modal-content">
          {publicKitLoading ? (
            <PageLoading />
          ) : (
            <>
              <Table
                style={{ maxWidth: 1160 }}
                data={publicTokenKits}
                colGroup={PUBLIC_KIT_COLGROUP}
                headers={PUBLIC_KIT_HEADER}
                renderer={PUBLIC_KIT_RENDERER}
                rowHeight={65}
                onClick={(data) => handleSelectKit(data)}
              />
              <PagingBar maxPageNum={Math.floor(maxPublicTokenKitsNum / TOKEN_KIT_LIMIT) + 1} query={'public-kit'} />
              <div style={{ display: 'flex', gap: 20, marginTop: 40 }}>
                <BasicButton theme={ButtonTheme.SECONDARY} size={ButtonSize.LARGE} onClick={closeModal}>
                  Close
                </BasicButton>
                <BasicButton theme={ButtonTheme.PRIMARY} size={ButtonSize.LARGE} onClick={addSelectedKits} disabled={selectedKits.length <= 0}>
                  Import ({selectedKits.length})
                </BasicButton>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
