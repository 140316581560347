import { v1ServerByProjectAuth } from "server/index/serverInstances";
import { makeQuery } from "server/index/utils";
import {
  checkNameForAccountManagerRequest,
  checkNameForAccountManagerResponse,
  createAccountManagerRequest,
  createAccountManagerResponse,
  getAccountManagerDetailRequest,
  getAccountManagerDetailResponse,
  getAccountManagerListRequest,
  getAccountManagerListResponse,
  signTransactionWithAccountManagerRequest,
  signTransactionWithAccountManagerResponse,
  signWithAccountManagerRequest,
  signWithAccountManagerResponse,
  verifyPasswordWithAccountManagerRequest,
  verifyPasswordWithAccountManagerResponse,
} from "./index.type";

const createAccountManager = async (
  request: createAccountManagerRequest
): Promise<createAccountManagerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/account-manager/accounts`,
    query: makeQuery(request.query),
    data: request.data,
  });

const getAccountManagerList = async (
  request: getAccountManagerListRequest
): Promise<getAccountManagerListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/account-manager/accounts`,
    query: makeQuery(request.query),
  });

const getAccountManagerDetail = async (
  request: getAccountManagerDetailRequest
): Promise<getAccountManagerDetailResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/account-manager/accounts/${request.params.accountId}`,
    query: makeQuery(request.query),
  });

const signWithAccountManager = async (
  request: signWithAccountManagerRequest
): Promise<signWithAccountManagerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/account-manager/accounts/${request.params.accountId}/sign`,
    query: makeQuery(request.query),
  });

const signTransactionWithAccountManager = async (
  request: signTransactionWithAccountManagerRequest
): Promise<signTransactionWithAccountManagerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/account-manager/accounts/${request.params.accountId}/sign-transaction`,
    query: makeQuery(request.query),
  });

const verifyPasswordWithAccountManager = async (
  request: verifyPasswordWithAccountManagerRequest
): Promise<verifyPasswordWithAccountManagerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/account-manager/accounts/${request.params.accountId}/verify-password`,
    query: makeQuery(request.query),
    data: request.data,
  });

const checkNameForAccountManager = async (
  request: checkNameForAccountManagerRequest
): Promise<checkNameForAccountManagerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/account-manager/accounts/check-name`,
    query: makeQuery(request.query),
    data: request.data,
  });
export {
  createAccountManager as createAccountManagerFromServer,
  getAccountManagerDetail as getAccountManagerDetailFromServer,
  getAccountManagerList as getAccountManagerListFromServer,
  signTransactionWithAccountManager as signTransactionWithAccountManagerFromServer,
  signWithAccountManager as signWithAccountManagerFromServer,
  verifyPasswordWithAccountManager as verifyPasswordWithAccountManagerFromServer,
  checkNameForAccountManager as checkNameForAccountManagerFromServer,
};
