import { TokenKit } from '../../../assets/constant/tokenKit.type';
import React, { useEffect, useState } from 'react';
import { logic } from '../../../../logic';
import { networkParserNew } from '../../../../utils/Utils';
import ModalTemplateB from '../../../components/_template/modal/ModalTemplateB';
import Select from '../../../components/_atom/select/Select';

type SelectNetworkModalProps = {
  isOpen: boolean;
  kitDetail: TokenKit;
  closeModal: () => void;
  selectedNetwork: any;
  setSelectedNetwork: (network: any) => void;
};
const SelectNetworkModal = (props: SelectNetworkModalProps) => {
  const { isOpen, kitDetail, closeModal, selectedNetwork, setSelectedNetwork } = props;
  const [networks, setNetworks] = useState<any[]>([]);
  const getNetworks = async () => {
    const { result, error } = await logic.tokenKit.getSupportedChainList(kitDetail?.version_id);
    if (error) {
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const supportedChains = result.map((chain) => {
      const network = networks.find((network) => network.id === chain.chainId);
      return network;
    });
    setNetworks(supportedChains);
  };
  useEffect(() => {
    getNetworks();
  }, [kitDetail?.version_id]);

  return (
    <ModalTemplateB
      isOpen={isOpen}
      onRequestClose={closeModal}
      title={'Select Network'}
      description={'Select the network you want to connect to.'}
      showDivideLine={false}
    >
      <div style={{ width: 520, marginBottom: 40 }}>
        {networks.length !== 0 && (
          <Select
            labelKey={'label'}
            valueKey={null}
            options={networks}
            s
            selectedValue={selectedNetwork}
            setSelectedValue={(el) => {
              setSelectedNetwork(el);
              closeModal();
            }}
            canSelect={networks.length !== 0}
            placeholder={'Select a network'}
            isAbsolute={false}
          />
        )}
      </div>
    </ModalTemplateB>
  );
};

export default SelectNetworkModal;
