import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { apiErrorCatcher } from "./apiErrorCatcher";
import { getRequestId, Queue } from "./Queue";
import { consoleError } from "./console";

type APIRequest = {
  path: string;
  data?: any;
  query?: string;
  baseURL?: string;
  config?: any;
};

export class Server {
  apiQueue: Queue;
  apiEvent: any;
  baseURL: string | undefined;

  serverInstance: AxiosInstance;

  constructor(apiQueue: Queue, apiEvent: any, baseURL?: string) {
    this.apiQueue = apiQueue;
    this.apiEvent = apiEvent;
    this.baseURL = baseURL;

    this.serverInstance = axios.create({
      baseURL,
      timeout: 20000,
    });
  }

  setAuthorizationToInstanceHeader(token: string) {
    this.serverInstance.defaults.headers.common.Authorization = token;
  }

  serverResponseInterceptor() {
    this.serverInstance.interceptors.response.use(
      function (response: AxiosResponse) {
        return response;
      },
      function (error: AxiosError) {
        if (process.env.NODE_ENV !== "production") {
          consoleError(error);
        }
        throw error;
      }
    );
  }

  async get({ path, query = "", baseURL, config }: APIRequest): Promise<any> {
    const api = {
      method: "get",
      baseURL: this.baseURL ?? (baseURL as string),
      path,
      query,
      config,
    };
    const requestId = getRequestId();
    return await new Promise((resolve, reject) => {
      this.apiEvent.once(requestId, (response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          showingErrorMessage(response);
          reject(response);
        }
      });
      this.apiQueue.addQueue(api, requestId);
    }).catch((e) => {
      throw apiErrorCatcher(e);
    });
  }

  async post({
    path,
    query = "",
    data,
    baseURL,
    config,
  }: APIRequest): Promise<any> {
    const api = {
      method: "post",
      baseURL: this.baseURL ?? (baseURL as string),
      path,
      query,
      data,
      config,
    };
    const requestId = getRequestId();
    return await new Promise((resolve, reject) => {
      this.apiEvent.once(requestId, (response: AxiosResponse) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        } else {
          showingErrorMessage(response);
          reject(response);
        }
      });
      this.apiQueue.addQueue(api, requestId);
    }).catch((e) => {
      throw apiErrorCatcher(e);
    });
  }

  async put({
    path,
    query = "",
    data,
    baseURL,
    config,
  }: APIRequest): Promise<any> {
    const api = {
      method: "put",
      baseURL: this.baseURL ?? (baseURL as string),
      path,
      query,
      data,
      config,
    };
    const requestId = getRequestId();
    return await new Promise((resolve, reject) => {
      this.apiEvent.once(requestId, (response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          showingErrorMessage(response);
          reject(response);
        }
      });
      this.apiQueue.addQueue(api, requestId);
    }).catch((e) => {
      throw apiErrorCatcher(e);
    });
  }

  async delete({
    path,
    query = "",
    baseURL,
    config,
  }: APIRequest): Promise<any> {
    const api = {
      method: "delete",
      baseURL: this.baseURL ?? (baseURL as string),
      path,
      query,
      config,
    };
    const requestId = getRequestId();
    return await new Promise((resolve, reject) => {
      this.apiEvent.once(requestId, (response: AxiosResponse) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          showingErrorMessage(response);
          reject(response);
        }
      });
      this.apiQueue.addQueue(api, requestId);
    }).catch((e) => {
      throw apiErrorCatcher(e);
    });
  }
}

function showingErrorMessage(error) {
  if (process.env.NODE_ENV !== "production") {
    consoleError(error);
  }
}
