import {
  checkNameForAccountManagerFromServer,
  createAccountManagerFromServer,
  getAccountManagerDetailFromServer,
  getAccountManagerListFromServer,
  verifyPasswordWithAccountManagerFromServer,
} from "logic/services/Account-Manager/server";
import { actionController } from "../utils";

export const accountManagerController = {
  //account 생성
  createAccountManager: (
    name: string,
    storageType: "KMS" | "MPC",
    password: string
  ) => {
    return actionController(async () => {
      try {
        const checkName = await checkNameForAccountManagerFromServer({
          query: {},
          data: {
            name,
          },
        });
        if (checkName.status === true) {
          throw new Error("Account_name_already_exists");
        }
        if (checkName.status === false) {
          const accountManager = await createAccountManagerFromServer({
            params: {},
            query: {},
            data: {
              name,
              storageType,
              usageType: "ADMIN",
              password,
            },
          });
          return accountManager;
        }
      } catch (e) {
        throw new Error("Failed_to_accountManager_create");
      }
    });
  },
  //account 조회
  getAccountManagerList: (option?: {
    lastId?: string;
    offset?: number;
    limit?: number;
    order?: "ASC" | "DESC";
  }) => {
    return actionController(async () => {
      try {
        const accountManager = await getAccountManagerListFromServer({
          params: {},
          query: {
            usageTypes: ["ADMIN"],
            ...(option?.lastId && { lastId: option.lastId }),
            ...(option?.offset && { offset: option.offset }),
            ...(option?.limit && { limit: option.limit }),
            ...(option?.order && { order: option.order }),
          },
        });
        const accountManagerList = {
          count: accountManager.total,
          rows: accountManager.data,
        };
        return accountManagerList;
      } catch (e) {
        throw new Error("Failed_to_accountManager_List");
      }
    });
  },
  //account 상세 조회
  getAccountManagerDetail: (accountId: string) => {
    return actionController(async () => {
      try {
        const accountManager = await getAccountManagerDetailFromServer({
          params: { accountId },
          query: {},
        });
        return accountManager;
      } catch (e) {
        throw new Error("Failed_to_accountManager_details");
      }
    });
  },

  //핀번호
  verifyPasswordWithAccountManager: (accountId: string, password: string) => {
    return actionController(async () => {
      try {
        const accountManager = await verifyPasswordWithAccountManagerFromServer(
          {
            params: { accountId },
            query: {},
            data: {
              password,
            },
          }
        );
        return accountManager;
      } catch (e) {
        throw new Error("verify_password_fail");
      }
    });
  },
};
