import React, { useMemo, useState } from 'react';
import ModalTemplateB from '../../../components/_template/modal/ModalTemplateB';
import { logic } from '../../../../logic';
import CommentInput from '../../../components/_atom/input/CommentInput';
import RadioButton from '../../../../legacy-common/atom/button/RadioButton';
import BasicButton from '../../../components/_atom/button/BasicButton';
import { ButtonTheme } from '../../../components/_atom/button/StyledButton';
import CopyToClipboard from 'react-copy-to-clipboard/lib';
import toast from 'react-hot-toast';
import { ReactComponent as IconCopy } from '../../../assets/images/icon_copy.svg';
import iconFailTransaction from '../../../assets/images/Icon_fail_transaction.png';
import EnterPinCodeModal from './EnterPinCodeModal';

const STORAGE_TYPE_OPTIONS = [
  {
    id: 'storage-type-kms',
    value: 'KMS',
    label: 'KMS - Managed safely on EQ Hub',
    disabled: false,
  },
  {
    id: 'storage-type-mpc',
    value: 'MPC',
    label: 'MPC - Managed more securely through distributed storage',
    disabled: true,
  },
];

const enum REGISTER_ACCOUNT_STEP {
  REGISTRATION = 0,
  COMPLETE = 1,
}

type RegisteredAccount = {
  account_id: string;
  project_id: number;
  is_active: boolean; //Account 활성화 여부
  name: string;
  address: string;
  storage_type: string;
  usage_type: string;
  secret_key: string; //생성한 accountManager의 secret key 정보 입니다.
  auth_methods: string[]; //Account의 인증 수단 정보입니다. ex)["PASSWORD", "SECRET_KEY"]
  created_at: string;
  update_at: string;
};
type RegisterAccountModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};
const RegisterAccountModal = (props: RegisterAccountModalProps) => {
  const { isOpen, closeModal } = props;
  const [step, setStep] = useState<REGISTER_ACCOUNT_STEP>(REGISTER_ACCOUNT_STEP.REGISTRATION);
  const [registeredAccount, setRegisteredAccount] = useState<RegisteredAccount>();
  const title = useMemo(() => {
    switch (step) {
      case REGISTER_ACCOUNT_STEP.REGISTRATION:
        return 'Register Account';
      case REGISTER_ACCOUNT_STEP.COMPLETE:
        return 'Account Registration Success';
    }
  }, [step]);
  const onClosed = () => {
    setStep(REGISTER_ACCOUNT_STEP.REGISTRATION);
    setRegisteredAccount(undefined);
    closeModal();
  };
  return (
    <ModalTemplateB isOpen={isOpen} title={title} showDivideLine={false} onRequestClose={onClosed} shouldCloseOnOverlayClick={false}>
      {step === REGISTER_ACCOUNT_STEP.REGISTRATION && <RegisterAccount onClosed={onClosed} setRegisteredAccount={setRegisteredAccount} setStep={setStep} />}
      {step === REGISTER_ACCOUNT_STEP.COMPLETE && <CompleteRegisterAccount onClosed={onClosed} registeredAccount={registeredAccount as RegisteredAccount} />}
    </ModalTemplateB>
  );
};

export default RegisterAccountModal;

const RegisterAccount = (props: {
  onClosed: () => void;
  setRegisteredAccount: (account: RegisteredAccount) => void;
  setStep: (step: REGISTER_ACCOUNT_STEP) => void;
}) => {
  const { onClosed, setRegisteredAccount, setStep } = props;
  const [name, setName] = useState<string>('');
  const [storageType, setStorageType] = useState<'KMS' | 'MPC'>();
  const [password, setPassword] = useState<string>('');
  const [isEnterPinCodeModalOpen, setIsEnterPinCodeModalOpen] = useState(false);

  const registerAccount = async () => {
    if (!name || !storageType || !password) {
      return;
    }
    const { result, error } = await logic.accountManager.createAccountManager(name.trim(), storageType, password);
    if (error) {
      console.log(error);
      return;
    }
    if (result) {
      setRegisteredAccount(result);
      setStep(REGISTER_ACCOUNT_STEP.COMPLETE);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 40, paddingLeft: 10, width: 652 }}>
      <EnterPinCodeModal isOpen={isEnterPinCodeModalOpen} closeModal={() => setIsEnterPinCodeModalOpen(false)} setPin={setPassword} pin={password} />
      <div className="kit-label-value-wrapper column">
        <div className="kit-label">
          Account Name
          <div className="kit-label required">*</div>
        </div>
        <CommentInput
          value={name}
          style={{ paddingLeft: 10 }}
          placeholder={'Enter your account name'}
          onChange={(e) => {
            setName(e.target.value.trimStart());
          }}
          maxLength={20}
        />
      </div>
      <div className="kit-label-value-wrapper column">
        <div className="kit-label">
          Account Management Type
          <div className="kit-label required">*</div>
        </div>
        <div className="kit-label-description" style={{ paddingLeft: 10 }}>
          You can choose how to store and manage the account you register.
        </div>
        <div className="radio-buttons" style={{ paddingLeft: 10 }}>
          <div className={`radio-button-container column`}>
            {STORAGE_TYPE_OPTIONS.map((el, key) => {
              if (!el) {
                return null;
              }
              return (
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }} key={key}>
                  <RadioButton
                    id={el.id ? el.id : el.label}
                    onChange={(e) => setStorageType(e.target.value as 'KMS' | 'MPC')}
                    value={el.value}
                    isSelected={storageType === el.value}
                    label={el.label}
                    labelStyle={{ paddingRight: 0 }}
                    disabled={el.disabled}
                  />
                  {el.disabled && (
                    <div
                      style={{
                        padding: '3px 10px',
                        borderRadius: 100,
                        background: '#C7E2FF',
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: '14px',
                        color: '#5888FF',
                      }}
                    >
                      Coming Soon
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="kit-label-value-wrapper column">
        <div className="kit-label">
          Pin Code
          <div className="kit-label required">*</div>
        </div>
        <div className="kit-label-description" style={{ paddingLeft: 10 }}>
          The value you need to enter when trying to use this account in the EQ Hub.
        </div>
        <CommentInput
          value={password}
          type={'password'}
          style={{ paddingLeft: 10, cursor: 'pointer', border: 'solid 2px #e7e9ef' }}
          placeholder={'Enter your pin code'}
          maxLength={6}
          onClick={() => setIsEnterPinCodeModalOpen(true)}
          readOnly
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
        <BasicButton theme={ButtonTheme.NORMAL} onClick={onClosed}>
          Close
        </BasicButton>
        <BasicButton onClick={registerAccount} disabled={!name || !storageType || !password}>
          Register
        </BasicButton>
      </div>
    </div>
  );
};

const CompleteRegisterAccount = (props: { onClosed: () => void; registeredAccount: RegisteredAccount }) => {
  const { onClosed, registeredAccount } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 40, paddingLeft: 10, width: 652 }}>
      <div className="kit-label-value-wrapper column">
        <div className="kit-label">Account ID</div>
        <div className="kit-value" style={{ paddingLeft: 10, gap: 10 }}>
          {registeredAccount.account_id}
          <CopyToClipboard text={registeredAccount.account_id} onCopy={() => toast('Account ID Copied', { icon: '👏' })}>
            <IconCopy style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} className="icon-copy" />
          </CopyToClipboard>
        </div>
      </div>
      <div className="kit-label-value-wrapper column">
        <div className="kit-label">Account Name</div>
        <div className="kit-value" style={{ paddingLeft: 10 }}>
          {registeredAccount.name}
        </div>
      </div>
      <div className="kit-label-value-wrapper column">
        <div className="kit-label">Address</div>
        <div className="kit-value" style={{ paddingLeft: 10, gap: 10 }}>
          {registeredAccount.address}
          <CopyToClipboard text={registeredAccount.address} onCopy={() => toast('Address Copied', { icon: '👏' })}>
            <IconCopy style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} className="icon-copy" />
          </CopyToClipboard>
        </div>
      </div>
      <div className="kit-label-value-wrapper column">
        <div className="kit-label">Secret Key</div>
        <div className="kit-value" style={{ paddingLeft: 10, gap: 10, width: '100%', wordBreak: 'break-all' }}>
          {registeredAccount.secret_key}
          <CopyToClipboard text={registeredAccount.secret_key} onCopy={() => toast('Secret Key Copied', { icon: '👏' })}>
            <IconCopy style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} className="icon-copy" />
          </CopyToClipboard>
        </div>
      </div>
      <div style={{ padding: 20, borderRadius: 8, background: '#EFEFEF' }}>
        <div style={{ display: 'flex', gap: 8, marginBottom: 12, alignItems: 'center' }}>
          <img src={iconFailTransaction} alt="icon_fail_transaction" style={{ width: 24, height: 24, objectFit: 'contain' }} />
          <div style={{ fontWeight: 700, fontSize: 16, lineHeight: '21px', color: '#E70707' }}>Note : Save your secret key</div>
        </div>
        <div
          style={{
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            whiteSpace: 'pre-line',
          }}
        >
          {'You will need your secret key when you access your service.\nIf you lose your secret key, all requests will not be available.'}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
        <BasicButton theme={ButtonTheme.PRIMARY} onClick={onClosed}>
          Close
        </BasicButton>
      </div>
    </div>
  );
};
