import { TokenKit } from '../../../assets/constant/tokenKit.type';
import TableStatus, { TABLE_STATUS } from './TableStatus';
import KitContainer from './KitContainer';
import LabelValue from './LabelValue';
import React from 'react';

const Setting = ({ kitDetail }: { kitDetail: TokenKit }) => {
  const status =
    kitDetail?.status === 4
      ? TABLE_STATUS.DEPLOYED
      : kitDetail?.status === 3
        ? TABLE_STATUS.PROCESSING
        : kitDetail?.status === 2
          ? TABLE_STATUS.PREPARED
          : TABLE_STATUS.CREATED;
  const datas = [
    { label: 'Kit ID', value: kitDetail?.kit_id },
    { label: 'Kit Name', value: kitDetail?.name },
    {
      label: 'Kit Image',
      value: <img src={kitDetail?.image} alt={''} className={'kit-info-image'} />,
    },
    { label: 'Token Symbol', value: kitDetail?.symbol },
    { label: 'States', value: <TableStatus status={status} /> },
    { label: 'Kit Version', value: kitDetail?.version?.version },
  ];
  return (
    <div className="tab-content-wrapper">
      <KitContainer
        title={'Kit Basic Info'}
        description="The Setting tab allows users to configure token details, manage access permissions, customize appearance, and optimize operational settings for seamless management."
      >
        {datas.map((data, index) => {
          return <LabelValue key={index} label={data.label} value={data.value} />;
        })}
      </KitContainer>
      {/*<div className="container" >*/}
      {/*  <div>*/}
      {/*    <div style={{ display: 'flex', gap: 8 }}>*/}
      {/*      <img src={iconWarning} alt="!" style={{ width: 24, height: 24, objectFit: 'contain' }} />*/}
      {/*      <div className="container-title" style={{ marginBottom: 12 }}>*/}
      {/*        Token Kit Deletion*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="container-description" style={{ color: '#E70707' }}>*/}
      {/*      Warning: This token will be permanently deleted. Deleted tokens cannot be recovered.*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <BasicButton style={{ alignSelf: 'flex-end' }} size={ButtonSize.ICON} theme={ButtonTheme.MINOR} onClick={() => {}}>*/}
      {/*    <img src={IconDeleteRed} style={{ width: 18, objectFit: 'contain' }} alt={'delete'} />*/}
      {/*  </BasicButton>*/}
      {/*</div>*/}
    </div>
  );
};

export default Setting;
