import styled, { css } from 'styled-components';

export const ScrollContainer = styled.div<{ scrollVisible: boolean }>`
  display: grid;
  grid-template-rows: auto 66px;
  width: 100%;
  height: 100%;
  background-color: #f5f6f8;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f6f8;
    -webkit-transition: background-color 0.3s;
  }

  ${({ scrollVisible }) => SCROLL_THUMB_CONTROLLER(scrollVisible)}
`;
const SCROLL_THUMB_CONTROLLER = (scrollVisible) => {
  if (scrollVisible) {
    return css`
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #b7b7b7;
        //transition: all ease-in-out 0.5s;
      }
    `;
  } else {
    return css`
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #b7b7b780;
      }
    `;
  }
};
