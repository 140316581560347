import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { user_info } from '../../../../../reducer/userReducer';
import './UserPanel.scss';
import Icon_user_setting from '../../../../assets/images/Icon_user_setting.png';
import { logoutAction } from '../../../../../action/userAction';
import { logout } from 'server/index/utils';

const UserPanel = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const userInfo = useSelector(user_info);

  const requestLogOut = async () => {
    await logout();
  };

  const goToUserProfile = () => {
    navigate('/user/profile');
  };

  return (
    <div className="user-info-panel" ref={ref}>
      <div className="user-info-panel-option-wrapper">
        <div className={'user-info-panel-option'} onClick={() => goToUserProfile()}>
          <div className="user-info-option-text">
            <div className="user-info-panel-name">{`${userInfo.firstName} ${userInfo.lastName}`}</div>
            <div style={{ fontSize: 12, color: '#7e7e7e' }}>{userInfo.email}</div>
          </div>
          <img className={'user-info-option-icon'} src={Icon_user_setting} alt="" />
        </div>
      </div>

      <div className="user-info-panel-option-wrapper">
        <div className={'user-info-panel-option'} onClick={() => requestLogOut()}>
          <div className="user-info-option-text">{'Sign Out'}</div>
        </div>
      </div>
    </div>
  );
});

export default UserPanel;
