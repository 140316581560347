import { parseAction } from "./util/parseAction";
import {
  acceptInvitationParser,
  checkProjectNameParser,
  createProjectParser,
  deleteInvitationParser,
  generateProjectTokenParser,
  getInvitedProjectListParser,
  getMicroChainIdsOfProjectParser,
  getMonthlyHistoryParser,
  getMyProjectByProjectTokenParser,
  getMyProjectsParser,
  getProjectReceiptParser,
  getPricingPlanParser,
  getPricingPlansParser,
  getProjectCreditParser,
  getProjectDefaultImageParser,
  getProjectInvitationListParser,
  getProjectMemberListParser,
  getProjectsParser,
  inviteProjectMemberParser,
  removeMemberParser,
  updateProjectParser,
  uploadProjectImageParser,
} from "../parser/projectParser";
import {
  selectProjectById,
  selected_project_id,
  setProjectToken,
  setSelectedProjectId,
  updateProject,
} from "../reducer/projectReducer";
import { getUserParser } from "parser/userParser";
import { storeDispatch, storeState } from "../reducer/index";
import { getUsersInformationParser } from "../parser/userParser";
import _ from "lodash";
import {
  getExternalAndInnerMicroChainListParser,
  getMicroChainListParser,
} from "parser/microChainParser";
import { networkParserNew } from "utils/Utils";
import { Project, ProjectMember } from "reducer/clientType/projectClientType";
import { User } from "reducer/clientType/userClientType";
import { getWEB3ProviderEndpointListParser } from "parser/authParser";
import { getMicroChainSettingParser } from "parser/networkParser";
import { getCurrenciesParser } from "parser/currencyParser";
import {
  CURRENCY_MAIN_STATUS,
  CURRENCY_TYPE_COIN,
} from "view/service/network/network/constants/networkConstants";
import { projectJWT } from "server/index/serverInstances";
import { eqhub } from "../App";
import { logic } from "logic";

export const getInitialDataForCreateProjectAction = () =>
  parseAction(async () => {
    const [defaultImage, innerPublicChainList, externalChainList, myProjects] =
      await Promise.all([
        getProjectDefaultImageParser(),
        getMicroChainListParser({ isPublic: true }),
        getMicroChainListParser({ external: true }),
        getMyProjectsParser(),
      ]);

    const networkList = networkParserNew([
      ...innerPublicChainList,
      ...externalChainList,
    ]);

    return {
      defaultImage,
      networkList,
      myProjects,
    };
  });

export const changeProjectAction = (projectId: number) =>
  parseAction(async () => {
    const result = await generateProjectTokenParser(projectId);
    projectJWT.setAccess(result.access);
    projectJWT.setRefresh(result.refresh);
    storeDispatch(setProjectToken(result));
    storeDispatch(setSelectedProjectId(projectId));
    eqhub.setProjectAccessToken(result.access.token);
    await logic.wallet.disconnectWallet();
    return result;
  });

export const createProjectAction = (
  name: string,
  image: string | File,
  pricingPlanId: number,
  options?: { description?: string; selectedMicroChainIds?: Array<number> }
) =>
  parseAction(async () => {
    let uploadProjectImageResult;
    if (typeof image !== "string") {
      let formData = new FormData();
      formData.append("projectImage", image);
      uploadProjectImageResult = await uploadProjectImageParser(formData, 1);
    } else {
      uploadProjectImageResult = image;
    }
    return await createProjectParser(
      name,
      uploadProjectImageResult,
      pricingPlanId,
      options
    );
  });

export const getInitialDataForUpdateProjectAction = (projectId: number) =>
  parseAction(async () => {
    const [
      defaultImage,
      publicMicroChainList,
      externalMicroChainList,
      microChainListInProject,
      projectDetail,
      selectedMicroChainIds,
    ] = await Promise.all([
      getProjectDefaultImageParser(),
      getMicroChainListParser({ isPublic: true }),
      getMicroChainListParser({ external: true }),
      getExternalAndInnerMicroChainListParser({ projectId }),
      getMyProjectByProjectTokenParser(),
      getMicroChainIdsOfProjectParser(),
    ]);

    const allPublicNetworkList = networkParserNew([
      ...publicMicroChainList,
      ...externalMicroChainList,
    ]);
    const networkListInProject = networkParserNew(microChainListInProject);
    const networkList = _.uniqBy(
      [...allPublicNetworkList, ...networkListInProject],
      "id"
    );
    return {
      defaultImage,
      networkList,
      projectDetail: {
        ...projectDetail,
        selectedMicroChainIds,
      },
    };
  });

export const updateProjectAction = (
  name: string,
  description: string,
  image: string | File,
  selectedMicroChainIds: Array<number>
) =>
  parseAction(async () => {
    let uploadProjectImageResult;
    if (typeof image !== "string") {
      let formData = new FormData();
      formData.append("projectImage", image);
      uploadProjectImageResult = await uploadProjectImageParser(formData, 1);
    } else {
      uploadProjectImageResult = image;
    }
    const result = await updateProjectParser(
      name,
      description,
      uploadProjectImageResult,
      selectedMicroChainIds
    );
    storeDispatch(updateProject(result));

    const microChainIds = await getMicroChainIdsOfProjectParser();
    const endpointsData = await getWEB3ProviderEndpointListParser({
      microChainIds,
    });

    const chainIds = endpointsData.map((el) => el.chainId);

    let endpointList: Array<any> = [];

    if (microChainIds.length !== 0) {
      const microChainSettingList = await Promise.all(
        chainIds.map(async (microChainId) => {
          return await getMicroChainSettingParser(microChainId);
        })
      );

      const currencyList = await getCurrenciesParser({
        originMicroChainIds: chainIds,
        type: CURRENCY_TYPE_COIN,
        mainStatus: CURRENCY_MAIN_STATUS,
      });
      const microChainList = await getExternalAndInnerMicroChainListParser({
        microChainIds: chainIds,
      });

      endpointList = endpointsData.map((endpoint) => {
        const microChainSetting = microChainSettingList.find(
          (microChain) => microChain.microChainId === endpoint.chainId
        );
        const currency = currencyList.find(
          (currency) => currency.originMicroChainId === endpoint.chainId
        );
        const microChain = microChainList.find(
          (chain) => chain.id === endpoint.chainId
        );

        return {
          id: endpoint.id,
          isTestnet: Boolean(microChainSetting.isTestnet),
          endpointKey: endpoint.socketApiKey,
          coinImage: currency.image,
          networkLabel: microChain.network.label,
        };
      });
    }
    return endpointList;
  });

export const inviteProjectMemberAction = (receiverEmail: string) =>
  parseAction(async () => {
    await inviteProjectMemberParser(receiverEmail);
    const invitationList = await getProjectInvitationListParser();

    return invitationList;
  });

export const getInvitedProjectListAction = () =>
  parseAction(async () => {
    const invitedList = await getInvitedProjectListParser();

    const projectIds = invitedList.map((el) => el.projectId);
    const projectDetailList = await getProjectsParser({ projectIds });

    const ownerIds = _.uniq(projectDetailList.map((el) => el.creatorId));
    const ownerDetailList = await getUsersInformationParser(ownerIds);

    const projectList = projectDetailList.map((project) => {
      const owner = ownerDetailList.find(
        (user) => user.id === project.creatorId
      );
      return {
        ...project,
        owner,
      };
    });

    const invitedProjectList = invitedList.map((el) => {
      const project = projectList.find(
        (project) => project.id === el.projectId
      );
      return {
        ...el,
        project,
      };
    });

    return invitedProjectList;
  });

export const deleteInvitationAction = (invitationId: number) =>
  parseAction(async () => {
    await deleteInvitationParser(invitationId);
    const invitationList = await getProjectInvitationListParser();

    return invitationList;
  });

export const removeMemberAction = (projectMemberId: number) =>
  parseAction(async () => {
    await removeMemberParser(projectMemberId);

    const projectMemberList = await getProjectMemberListParser();

    const memberIds = projectMemberList.map((member) => member.userId);
    const usersInformation = await getUsersInformationParser(memberIds);

    const memberList = projectMemberList.map((member) => {
      const memberInformation = usersInformation.find(
        (user) => user.id === member.userId
      );

      return {
        ...member,
        ...memberInformation,
      };
    });

    return memberList;
  });

export const acceptInvitationAction = (invitationIds: Array<number>) =>
  parseAction(async () => {
    return await acceptInvitationParser(invitationIds);
  });

export const checkProjectNameAction = (name: string) =>
  parseAction(async () => {
    return await checkProjectNameParser(name);
  });

export const getProjectInfoAction = () =>
  parseAction(async () => {
    const projectId = selected_project_id(storeState());
    const projectDetail = selectProjectById(storeState(), projectId) as Project;

    const owner = await getUserParser(projectDetail.creatorId);
    const projectMemberList = await getProjectMemberListParser();

    return {
      owner,
      memberCount: projectMemberList.length,
    };
  });

export const getProjectMemberInfoAction = () =>
  parseAction(async () => {
    const [projectMemberList, invitationList] = await Promise.all([
      getProjectMemberListParser(),
      getProjectInvitationListParser(),
    ]);

    const projectId = selected_project_id(storeState());
    const projectDetail = selectProjectById(storeState(), projectId) as Project;

    const memberIds = projectMemberList.map(
      (member: ProjectMember) => member.userId
    );
    const usersInformation = await getUsersInformationParser(memberIds);

    const memberList: Array<ProjectMember & User> = projectMemberList.map(
      (member: ProjectMember) => {
        const MemberInformation = usersInformation.find(
          (user: User) => user.id === member.userId
        ) as User;
        return {
          ...member,
          ...MemberInformation,
        };
      }
    );
    const ownerMember = memberList.find(
      (member) => member.userId === projectDetail.creatorId
    );
    const sortedMemberList = [ownerMember].concat(
      memberList.filter((member) => member.userId !== projectDetail.creatorId)
    );

    return {
      memberList: sortedMemberList,
      invitationList,
    };
  });

export const getPricingPlansAction = () =>
  parseAction(async () => {
    const result = await getPricingPlansParser();

    return result;
  });

export const getPricingPlanAction = (pricingPlanId: number) =>
  parseAction(async () => {
    const result = await getPricingPlanParser(pricingPlanId);

    return result;
  });

export const getProjectBillingInfoAction = (
  pricingPlanId: number,
  limit: number
) =>
  parseAction(async () => {
    const [pricingPlan, monthlyHistory, projectCredit, projectReceipt] =
      await Promise.all([
        getPricingPlanParser(pricingPlanId),
        getMonthlyHistoryParser(),
        getProjectCreditParser(),
        getProjectReceiptParser(0, limit),
      ]);

    const estimatedCost = [
      {
        name: "monthlyPrice",
        amount: Number(pricingPlan.monthlyPrice),
      },
      {
        name: "extraChargePrice",
        amount:
          Number(monthlyHistory.exchangeRate) *
          Number(monthlyHistory.extraChargeCredit),
      },
      {
        name: "infraUsagePrice",
        amount:
          Number(monthlyHistory.exchangeRate) *
          Number(monthlyHistory.infraUsageCredit),
      },
    ].filter((el) => el.amount !== 0);

    const response = {
      pricingPlan,
      monthlyHistory,
      projectCredit,
      projectReceipt,
      estimatedCost,
    };

    return response;
  });

export const getProjectReceiptAction = (page: number, limit: number) =>
  parseAction(async () => {
    const RECEIPTS_OFFSET = (page - 1) * limit;

    const result = await getProjectReceiptParser(RECEIPTS_OFFSET, limit);
    return result;
  });
