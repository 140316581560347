import React, { useEffect, useState } from 'react';
import ModalTemplateB from '../../../components/_template/modal/ModalTemplateB';
import './EnterPinCodeModal.scss';
import smallLogo from '../../../assets/images/logo_small.png';
import deleteImg from '../../../assets/images/img_delete.png';

const generateRandomPad = () => {
  const numArray = [...Array(10).keys(), -1, -1]; // [0, 1, 2, ..., 9, -1, -1]
  for (let i = numArray.length - 1; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1));
    [numArray[i], numArray[randomIndex]] = [numArray[randomIndex], numArray[i]];
  }
  return numArray;
};

type EnterPinCodeModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  pin: string;
  setPin: (pin: string) => void;
};
const EnterPinCodeModal = (props: EnterPinCodeModalProps) => {
  const { isOpen, closeModal, pin, setPin } = props;
  const [randomNumbers, setRandomNumbers] = useState<number[]>([]);
  useEffect(() => {
    if (isOpen) {
      setPin('');
    }
    setRandomNumbers(generateRandomPad());
  }, [isOpen]);
  useEffect(() => {
    if (pin.length === 6) {
      closeModal();
    }
  }, [pin]);
  return (
    <ModalTemplateB
      isOpen={isOpen}
      title={'Enter Pin Code'}
      description={'Please enter your PIN code to verify.\nThis extra step helps protect your account security'}
      showDivideLine={false}
      onRequestClose={closeModal}
    >
      <div id="EnterPinCodeModal">
        <div className="pin-code-circle-wrapper">
          {Array.from({ length: 6 }, (_, i) => i).map((index) => (
            <div className={`pin-code-circle${pin.length > index ? ' active' : ''}`} key={index} />
          ))}
        </div>
        <div className="pin-code-button-wrapper">
          {randomNumbers.map((num, index) => (
            <button key={index} className={`pin-code-button${num === -1 ? ' logo' : ''}`} onClick={() => setPin(pin + num)} disabled={num === -1}>
              {num === -1 ? <img src={smallLogo} alt={'EQ'} /> : num}
            </button>
          ))}
          <button className={`pin-code-button clear`} onClick={() => setPin('')}>
            All Clear
          </button>
          <button className={`pin-code-button delete`} onClick={() => setPin('')}>
            <img src={deleteImg} alt={'Delete'} />
          </button>
        </div>
      </div>
    </ModalTemplateB>
  );
};

export default EnterPinCodeModal;
