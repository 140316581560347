import { ConnectedWallet, KitFunction } from '../../../assets/constant/kit.type';
import { TokenKit } from '../../../assets/constant/tokenKit.type';
import { TOKEN_KIT_FUNCTION_METHOD } from '../../../assets/constant/tokenKit.constant';
import React, { useEffect, useState } from 'react';
import KitContainer from './KitContainer';
import DivideLine from '../../../components/_atom/line/DivideLine';
import CommentInput from '../../../components/_atom/input/CommentInput';
import deleteButtonImage from '../../../assets/images/button_delete.png';
import BasicButton from '../../../components/_atom/button/BasicButton';
import { ButtonSize, ButtonTheme } from '../../../components/_atom/button/StyledButton';
import BasicTable from '../../../components/_atom/table/BasicTable';
import CopyToClipboard from 'react-copy-to-clipboard/lib';
import toast from 'react-hot-toast';
import iconCopy from '../../dashboard/assets/Icon_copy_address.png';
import './LabelValue.scss';
import EQConnectWalletButtonWithMetamask from '../../../../modules/EQConnect/EQConnectWalletButtonWithMetamask';
import EnterPinCodeModal from './EnterPinCodeModal';

const TRANSFER_COLGROUP = [1, 1.1, 1, 1, 1.3];
const TRANSFER_HEADERS = [
  { title: 'TX Hash', accessor: 'tx_hash' },
  { title: 'Date', accessor: 'timestamp' },
  { title: 'From', accessor: 'sender' },
  { title: 'To', accessor: 'receiver' },
  { title: 'Amount', accessor: 'amount' },
];
const TRANSFER_RENDERER = (data: any) => {
  return {
    tx_hash: (
      <div style={{ display: 'flex', gap: 10 }}>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={data.tx_hash}
        >
          {data.tx_hash}
        </div>
        <CopyToClipboard text={data.tx_hash} onCopy={() => toast('Copied TX Hash', { icon: '👏' })}>
          <img src={iconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
        </CopyToClipboard>
      </div>
    ),
    timestamp: data.timestamp,
    sender: (
      <div style={{ display: 'flex', gap: 10 }}>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={data.sender}
        >
          {data.sender}
        </div>
        <CopyToClipboard text={data.sender} onCopy={() => toast('Copied Sender Address', { icon: '👏' })}>
          <img src={iconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
        </CopyToClipboard>
      </div>
    ),
    receiver: (
      <div style={{ display: 'flex', gap: 10 }}>
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={data.receiver}
        >
          {data.receiver}
        </div>
        <CopyToClipboard text={data.receiver} onCopy={() => toast('Copied Receiver Address', { icon: '👏' })}>
          <img src={iconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
        </CopyToClipboard>
      </div>
    ),
    amount: data.amount,
  };
};

const APPROVAL_COLGROUP = [1, 1.1, 1, 1, 1.3];
const APPROVAL_HEADERS = [
  { title: 'TX Hash', accessor: 'tx_hash' },
  { title: 'Date', accessor: 'timestamp' },
  { title: 'Approver', accessor: 'approver' },
  { title: 'Spender', accessor: 'spender' },
  { title: 'Allowance', accessor: 'allowance' },
];
const APPROVAL_RENDERER = (data: any) => ({
  tx_hash: (
    <div style={{ display: 'flex', gap: 10 }}>
      <div title={data.tx_hash}>{data.tx_hash}</div>
      <CopyToClipboard text={data.tx_hash} onCopy={() => toast('Copied TX Hash', { icon: '👏' })}>
        <img src={iconCopy} alt="copy" style={{ width: 20, height: 20, objectFit: 'contain', cursor: 'pointer' }} />
      </CopyToClipboard>
    </div>
  ),
  timestamp: data.timestamp,
  approver: data.approver,
  spender: data.spender,
  allowance: data.allowance,
});

const MethodForm = ({ method, selectedFunction, kitDetail }: { method: any; selectedFunction?: KitFunction; kitDetail?: TokenKit }) => {
  const { method_id, input_type, method_description, method: methodName, input, need_wallet } = method;
  const selectedFunctionMethods = TOKEN_KIT_FUNCTION_METHOD[selectedFunction?.feature_id ?? 1];
  const currentMethod = selectedFunctionMethods.find((item) => item.method_id === method_id);
  const [inputValues, setInputValues] = useState<any>();
  const [result, setResult] = useState<any>();
  useEffect(() => {
    if (input_type === 'single') {
      setInputValues({});
    } else {
      setInputValues([{}]);
    }
    setResult(undefined);
  }, [selectedFunction]);

  const [connectedWallet, setConnectedWallet] = useState<ConnectedWallet>({
    connectWalletType: '',
    connectAddress: '',
  });
  const [isOpenEnterPinCodeModal, setIsOpenEnterPinCodeModal] = useState(false);
  const [password, setPassword] = useState<string>('');
  const runMethod = async () => {
    const result = await currentMethod.onClick(inputValues, {
      kitId: kitDetail?.kit_id,
      microChainId: kitDetail?.chain_id,
      contractAddress: kitDetail?.contract_address,
      connectedWallet,
      password,
    });
    setResult(result);
  };

  const colgroup = method_id === 105 ? TRANSFER_COLGROUP : APPROVAL_COLGROUP;
  const headers = method_id === 105 ? TRANSFER_HEADERS : APPROVAL_HEADERS;
  const renderer = method_id === 105 ? TRANSFER_RENDERER : APPROVAL_RENDERER;
  return (
    <KitContainer title={methodName} description={method_description}>
      <EnterPinCodeModal
        isOpen={isOpenEnterPinCodeModal}
        closeModal={async () => {
          setIsOpenEnterPinCodeModal(false);
          if (password.length === 6) {
            await runMethod();
          }
          setPassword('');
        }}
        pin={password}
        setPin={setPassword}
      />
      <DivideLine />
      {input_type === 'single' ? (
        <>
          {input?.map((input, index) => (
            <div style={{ paddingLeft: 10 }} key={'input' + index}>
              <div className="kit-label-value-wrapper column">
                <div className="kit-label">{input.name}</div>
                <CommentInput
                  value={inputValues?.[input.name] ?? ''}
                  disabled={kitDetail?.status !== 4}
                  placeholder={currentMethod?.inputs.find((item) => item.name === input.name)?.placeholder}
                  onChange={(e) => {
                    setInputValues({ ...inputValues, [input.name]: e.target.value });
                  }}
                  maxLength={55}
                />
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {inputValues?.map((_, valueIndex) => (
            <div style={{ display: 'grid', gridTemplateColumns: '1.8fr 1fr 20px', gap: 10 }} key={valueIndex}>
              {input?.map((input, index) => (
                <div key={'input' + index}>
                  <div className="kit-label-value-wrapper column">
                    {valueIndex === 0 && <div className="kit-label">{input.name}</div>}
                    <CommentInput
                      value={inputValues?.[valueIndex]?.[input.name] ?? ''}
                      disabled={kitDetail?.status !== 4}
                      placeholder={currentMethod?.inputs.find((item) => item.name === input.name)?.placeholder}
                      onChange={(e) => {
                        setInputValues((prev) => {
                          const newInputValues = [...prev];
                          newInputValues[valueIndex] = {
                            ...newInputValues[valueIndex],
                            [input.name]: e.target.value,
                          };
                          return newInputValues;
                        });
                      }}
                      maxLength={55}
                    />
                  </div>
                </div>
              ))}
              {valueIndex !== 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img
                    src={deleteButtonImage}
                    alt="delete"
                    style={{ cursor: 'pointer', width: 16, height: 16, objectFit: 'contain' }}
                    onClick={() => {
                      setInputValues(inputValues.filter((_, index) => index !== valueIndex));
                    }}
                  />
                </div>
              )}
            </div>
          ))}
          <BasicButton
            size={ButtonSize.SMALL}
            style={{ alignSelf: 'center', height: 34, width: 103 }}
            theme={ButtonTheme.SECONDARY}
            onClick={() => {
              setInputValues([...inputValues, {}]);
            }}
          >
            + Add Row
          </BasicButton>
        </>
      )}
      {kitDetail?.status === 4 && (
        <>
          <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
            {need_wallet && <EQConnectWalletButtonWithMetamask size={'small'} setConnectedWallet={setConnectedWallet} connectedWallet={connectedWallet} />}
            <BasicButton
              size={ButtonSize.DYNAMIC}
              style={{ width: 200, height: 40 }}
              theme={ButtonTheme.PRIMARY}
              onClick={async () => {
                if (need_wallet && connectedWallet.connectAddress === '') {
                  toast.error('Please connect wallet first');
                  return;
                }
                if (need_wallet && password.length !== 6 && connectedWallet.connectWalletType === 'accountManager') {
                  setIsOpenEnterPinCodeModal(true);
                  return;
                }
                await runMethod();
              }}
            >
              Send
            </BasicButton>
          </div>
          {result && (
            <div className="method-result-wrapper">
              {method.method_id === 105 || method.method_id === 403 ? (
                <BasicTable data={result} colGroup={colgroup} headers={headers} renderer={renderer} />
              ) : (
                <>
                  {need_wallet ? (
                    <>
                      <div className="method-result-text" style={{ marginBottom: 10 }}>
                        Request : Success!
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="method-result-text">TX Hash : {result}</div>
                        <CopyToClipboard text={result} onCopy={() => toast('Copied TX Hash', { icon: '👏' })}>
                          <img
                            src={iconCopy}
                            alt="copy"
                            style={{
                              width: 20,
                              height: 20,
                              objectFit: 'contain',
                              cursor: 'pointer',
                            }}
                          />
                        </CopyToClipboard>
                      </div>
                    </>
                  ) : (
                    <div>{JSON.stringify(result).replace(/["{}]/g, '').replace(':', ' : ')}</div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </KitContainer>
  );
};

export default MethodForm;
