import { APIError, RequestError } from "./APIError";
import axios from "axios";
import { USER_ERROR_MESSAGE } from "./userErrorMessage";
import { ERROR_TYPE_LOGIN_EXPIRED } from "./utils";

// 이름 변경 (requestErrorCatcher)
export const apiErrorCatcher = (e: any) => {
  let apiError: RequestError = {
    type: undefined,
    data: {
      baseURL: undefined,
      path: undefined,
      message: undefined,
      status: undefined,
      serverStatus: undefined,
    },
  };

  if (axios.isAxiosError(e)) {
    apiError = {
      ...apiError,
      type: "API",
      data: {
        ...apiError.data,
        ...(e?.response?.config?.baseURL && {
          baseURL: e?.response?.config?.baseURL,
        }),
        ...(e?.response?.config?.url && { path: e?.response?.config?.url }),
        ...(e?.response?.status && { status: e?.response?.status?.toString() }),
      },
    };
    if (e.response?.data.serverStatusCode) {
      const serverErrorCode = e.response.data.serverStatusCode;
      const clientErrorMessage: string | undefined = USER_ERROR_MESSAGE.find(
        (el) => el.serverStatusCode === serverErrorCode
      )?.message;
      apiError = {
        ...apiError,
        data: {
          ...apiError.data,
          message: clientErrorMessage,
          errorCode: e.response?.data.error.code,
          serverStatus: e.response.data.serverStatusCode,
          ...(e?.response?.data?.data && {
            data: e?.response?.data?.data[0]?.reason,
          }),
        },
      };
    } else {
      apiError = {
        ...apiError,
        data: {
          ...apiError.data,
          ...(e?.response?.data?.message && {
            message: e?.response?.data?.message,
          }),
          errorCode: e.response?.data.error.code,
        },
      };
    }
  }

  if (e?.type === ERROR_TYPE_LOGIN_EXPIRED) {
    apiError = {
      type: ERROR_TYPE_LOGIN_EXPIRED,
      data: {
        ...e.data,
      },
    };
  }

  return apiError;
};
