import React, { useEffect, useMemo, useState } from 'react';
import ModalTemplateB from '../../../components/_template/modal/ModalTemplateB';
import RadioButton from '../../../../legacy-common/atom/button/RadioButton';
import { Account } from '../../../assets/constant/accountManager.type';
import { logic } from '../../../../logic';
import useLoading from '../../../../hooks/useLoading';
import { ConnectedWallet } from '../../../assets/constant/kit.type';
import BasicButton from '../../../components/_atom/button/BasicButton';
import importIcon from '../../../assets/images/icon-import.png';
import connectIcon from '../../../assets/images/icon-connect.png';
import { requestAddressToMetamask } from '../../../../modules/EQExtension/utils/messageUtils';
import PageLoading from '../../../components/_atom/loading/PageLoading';
import { ButtonSize, ButtonTheme } from '../../../components/_atom/button/StyledButton';
import EmptyDataTemplate from '../../dashboard/components/EmptyDataTemplate';
import RegisterAccountModal from './RegisterAccountModal';

const enum SELECT_ACCOUNT_STEP {
  SELECT_ACCOUNT_TYPE,
  SELECT_ACCOUNT,
}

const ACCOUNT_TYPES = [
  { id: 1, label: 'Import from Account Manager', description: "Make it even easier with EQ Hub's Account Manager" },
  {
    id: 2,
    label: 'Connect wallet service (e.g. Metamask)',
    description: 'Connect to your existing wallet service, such as Metamask',
  },
];

type ConnectWalletModalProps = {
  isOpen: boolean;
  closeModal: (connectedWallet?: ConnectedWallet) => void;
  setConnectedWallet: (connectedWallet: ConnectedWallet) => void;
};
const ConnectWalletModal = (props: ConnectWalletModalProps) => {
  const { isOpen, closeModal, setConnectedWallet } = props;
  const [step, setStep] = useState<SELECT_ACCOUNT_STEP>(SELECT_ACCOUNT_STEP.SELECT_ACCOUNT_TYPE);
  const title = useMemo(() => {
    switch (step) {
      case SELECT_ACCOUNT_STEP.SELECT_ACCOUNT_TYPE:
        return 'Select to Connect Wallet';
      case SELECT_ACCOUNT_STEP.SELECT_ACCOUNT:
        return 'Select Account';
    }
  }, [step]);
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const onClosed = (connectedWallet?: ConnectedWallet) => {
    setStep(SELECT_ACCOUNT_STEP.SELECT_ACCOUNT_TYPE);
    setSelectedAccount(undefined);
    closeModal(connectedWallet);
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, [isOpen]);
  return (
    <ModalTemplateB isOpen={isOpen} title={title} showDivideLine={false} onRequestClose={onClosed as any}>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
        >
          <PageLoading style={{ height: 246 }} />
        </div>
      )}
      <div style={{ width: 652 }}>
        {step === SELECT_ACCOUNT_STEP.SELECT_ACCOUNT_TYPE && (
          <SelectAccountType setConnectedWallet={setConnectedWallet} setStep={setStep} onClosed={onClosed} setLoading={setLoading} />
        )}
        {step === SELECT_ACCOUNT_STEP.SELECT_ACCOUNT && (
          <SelectAccount
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            setStep={setStep}
            setConnectedWallet={setConnectedWallet}
            onClosed={onClosed}
          />
        )}
      </div>
    </ModalTemplateB>
  );
};

export default ConnectWalletModal;

const SelectAccountType = ({
  setConnectedWallet,
  setStep,
  onClosed,
  setLoading,
}: {
  setConnectedWallet: (connectedWallet: ConnectedWallet) => void;
  setStep: (step: SELECT_ACCOUNT_STEP) => void;
  onClosed: (connectedWallet?: ConnectedWallet) => void;
  setLoading: (loading: boolean) => void;
}) => {
  const getAddress = async () => {
    const { result, error } = await logic.wallet.getConnectWalletData();
    if (error) {
      return;
    }
    const connectedWallet = { ...result, accountId: result?.accountId || undefined };
    setConnectedWallet({ ...result, accountId: result?.accountId || undefined });
    return connectedWallet;
  };
  const connectWalletToMetamask = async () => {
    const result = await requestAddressToMetamask('metamask');
    if (result) {
      const connectedWallet = await getAddress();
      onClosed(connectedWallet);
    }
    setLoading(false);
  };
  const connectWalletToAccountManager = async () => {
    setStep(SELECT_ACCOUNT_STEP.SELECT_ACCOUNT);
  };
  return (
    <div>
      {ACCOUNT_TYPES.map((accountType, index) => {
        return (
          <div
            key={index}
            onClick={async () => {
              if (accountType.id === 1) {
                await connectWalletToAccountManager();
              } else {
                setLoading(true);
                await connectWalletToMetamask();
              }
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 20,
              marginBottom: 20,
              padding: 40,
              borderRadius: 16,
              backgroundColor: '#E5E8EE80',
              cursor: 'pointer',
            }}
          >
            <img src={accountType.id === 1 ? importIcon : connectIcon} alt="icon" style={{ width: 52, height: 52, objectFit: 'contain' }} />
            <div>
              <div style={{ fontSize: 22, fontWeight: 500, lineHeight: '26px' }}>{accountType.label}</div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: '16px',
                  color: '#33373B',
                }}
              >
                {accountType.description}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const SelectAccount = ({
  selectedAccount,
  setSelectedAccount,
  setStep,
  setConnectedWallet,
  onClosed,
}: {
  selectedAccount: Account | undefined;
  setSelectedAccount: (account: Account) => void;
  setStep: (step: SELECT_ACCOUNT_STEP) => void;
  setConnectedWallet: (connectedWallet: ConnectedWallet) => void;
  onClosed: (connectedWallet?: ConnectedWallet) => void;
}) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const getAccounts = async () => {
    const { result, error } = await logic.accountManager.getAccountManagerList();
    if (error) {
      console.log(error);
      return;
    }
    const { rows: accounts } = result;
    setAccounts(accounts);
  };
  const connectAccount = async () => {
    if (!selectedAccount) {
      return;
    }
    const { result, error } = await logic.wallet.setConnectWalletData('accountManager', selectedAccount.address, selectedAccount.account_id);
    if (error) {
      console.log(error);
      return;
    }
    if (result) {
      const connectedWallet = {
        connectWalletType: 'accountManager',
        connectAddress: selectedAccount.address,
        accountId: selectedAccount.account_id,
      };
      setConnectedWallet(connectedWallet);
      onClosed(connectedWallet);
    }
  };
  const [isOpenRegisterAccountModal, setIsOpenRegisterAccountModal] = useState(false);

  const { loading } = useLoading({
    dependency: [isOpenRegisterAccountModal],
    synchronousFunction: async () => {
      if (!isOpenRegisterAccountModal) {
        await getAccounts();
      }
    },
  });
  return (
    <div>
      <RegisterAccountModal isOpen={isOpenRegisterAccountModal} closeModal={() => setIsOpenRegisterAccountModal(false)} />
      <>
        {!loading && accounts.length === 0 ? (
          <>
            <EmptyDataTemplate>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  lineHeight: '21px',
                  color: '#7D828D',
                }}
              >
                {'No available accounts\nCreate a new account or edit the scope of accounts you have.'}
              </div>
            </EmptyDataTemplate>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginBottom: 40,
              }}
            >
              <BasicButton size={ButtonSize.SMALL} theme={ButtonTheme.NORMAL} onClick={() => setIsOpenRegisterAccountModal(true)}>
                + New Account
              </BasicButton>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '19px',
                color: '#7F879E',
                marginBottom: 20,
              }}
            >
              Account List
            </div>
            {loading ? (
              <PageLoading style={{ height: 246, marginBottom: 40 }} />
            ) : (
              <div style={{ height: 246, overflowY: 'auto', marginBottom: 40 }}>
                {accounts.map((account, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectedAccount(account)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '12px 10px',
                        borderTop: '1px solid #EFEFEF',
                        borderBottom: '1px solid #EFEFEF',
                      }}
                    >
                      <RadioButton checked={selectedAccount?.account_id === account.account_id} label={account.name} onChange={() => {}} />
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </>
      <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
        <BasicButton theme={ButtonTheme.NORMAL} onClick={() => setStep(SELECT_ACCOUNT_STEP.SELECT_ACCOUNT_TYPE)}>
          Back
        </BasicButton>
        <BasicButton onClick={connectAccount}>Select</BasicButton>
      </div>
    </div>
  );
};
