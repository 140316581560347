import React from 'react';
import './TableStatus.scss';

import readyIcon from '../../../components/_atom/status/assets/icon_status_ready_white.png';
import rejectIcon from '../../../components/_atom/status/assets/icon_status_reject.png';
import createdIcon from '../../../components/_atom/status/assets/icon_status_created.png';
import processingIcon from '../../../components/_atom/status/assets/icon_status_processing.png';
import completeIcon from '../../../components/_atom/status/assets/icon_status_complete.png';

export const enum TABLE_STATUS {
  READY = 'Ready',
  REJECTED = 'Rejected',
  CREATED = 'Created',
  SUBMITTED = 'Submitted',
  PREPARED = 'Prepared',
  PROCESSING = 'Processing',
  REQUESTED = 'Requested',
  DEPLOYED = 'Deployed',
}

type TableStatusProps = {
  status: TABLE_STATUS;
};

const TableStatus = (props: TableStatusProps) => {
  const { status } = props;
  const icon = () => {
    switch (status) {
      case TABLE_STATUS.READY:
        return readyIcon;
      case TABLE_STATUS.REJECTED:
        return rejectIcon;
      case TABLE_STATUS.CREATED:
        return createdIcon;
      case TABLE_STATUS.SUBMITTED:
        return processingIcon;
      case TABLE_STATUS.PREPARED:
        return completeIcon;
      case TABLE_STATUS.PROCESSING:
        return processingIcon;
      case TABLE_STATUS.REQUESTED:
        return processingIcon;
      case TABLE_STATUS.DEPLOYED:
        return completeIcon;
      default:
        return readyIcon;
    }
  };
  return (
    <div className={`table-status ${status}`}>
      <img src={icon()} alt={status} className="table-status-icon" />
      <span className="table-status-text">{status}</span>
    </div>
  );
};

export default TableStatus;
