import { services } from "logic/services";
import { storeDispatch, storeState } from "reducer/index";
import {
  connectInfo,
  removeConnectedInfo,
  setConnectedInfo,
} from "store/redux/EQconnect/EQConnectSlice";
import { actionController } from "../utils";
const { wallet } = services;
export const walletController = {
  connectWallet: (connectWalletType: "metamask" | "eqhub") => {
    return actionController(async () => {
      let address: string | null = null;
      let chainId: string | undefined;

      if (connectWalletType === "metamask") {
        if (window.ethereum && window.ethereum.isMetaMask) {
          try {
            chainId = await window.ethereum.request({
              method: "net_version",
            });

            const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
            });
            address = accounts[0];
            if (!address) {
              throw new Error("metamask_account_notFound");
            }
          } catch (error) {
            throw new Error("metamask_connect_fail");
          }
        } else {
          throw new Error("metamask_not_installed");
        }
      } else if (connectWalletType === "eqhub") {
        const message = {
          event: "",
          payload: {},
        };
        setTimeout(() => {
          const newMessage = { ...message };
          newMessage.event = "getAddress";
          newMessage.payload = {};
          window.postMessage(newMessage, window.origin);
        }, 200);
        address = sessionStorage.getItem("address");
      }

      if (address && connectWalletType) {
        await wallet.sessionStorage.saveAddress(address);
        await wallet.sessionStorage.setConnectedWalletType(connectWalletType);
        storeDispatch(
          setConnectedInfo({
            address: address,
            type: "extension",
          })
        );
      }

      return { chainId, address, connectWalletType };
    });
  },

  disconnectWallet: () => {
    return actionController(async () => {
      const address = await wallet.sessionStorage.getAddress();

      if (address) {
        try {
          // await window.ethereum.request({
          //   method: "wallet_revokePermissions",
          //   params: [
          //     {
          //       eth_accounts: {},
          //     },
          //   ],
          // });
          await wallet.sessionStorage.removeAddress();
          await wallet.sessionStorage.deleteConnectedWalletType();
          await wallet.sessionStorage.removeConnectedWalletId();
          sessionStorage.removeItem("address");
          sessionStorage.removeItem("type");
          storeDispatch(removeConnectedInfo());
          return true;
        } catch (error) {
          throw new Error("remove_account_fail");
        }
      } else {
        throw new Error("account_does_not_exist");
      }
    });
  },

  getConnectWallet: () => {
    return actionController(async () => {
      try {
        const walletType = await wallet.sessionStorage.getConnectedWalletType();
        if (walletType) {
          //metamask나 accountManager 반환
          return walletType;
        } else {
          return "eqhub";
        }
      } catch {
        throw new Error("walletType_get_fail");
      }
    });
  },

  setConnectWalletData: (
    connectWalletType: "metamask" | "eqhub" | "accountManager",
    address: string,
    id?: string
  ) => {
    return actionController(async () => {
      try {
        let setWalletId;
        if (id) {
          setWalletId = await wallet.sessionStorage.setConnectedWalletId(id);
        }

        const setWalletType =
          await wallet.sessionStorage.setConnectedWalletType(connectWalletType);
        await wallet.sessionStorage.saveAddress(address);

        const walletData = {
          connectWalletType: setWalletType ? connectWalletType : undefined,
          connectAddress: address ? address : undefined,
          id: id ? id : undefined,
        };
        return walletData;
      } catch {
        throw new Error("walletData_set_fail");
      }
    });
  },

  //metamask,account manager가 연동되었다면 보여주고, 그렇지 않으면 EQextension을 보여줌
  getConnectAddress: () => {
    return actionController(async () => {
      try {
        const walletType = await wallet.sessionStorage.getConnectedWalletType();
        if (walletType) {
          throw new Error("walletType_does_not_exist");
        }

        const connectWalletAddress = await wallet.sessionStorage.getAddress();
        const EQwalletAddress = sessionStorage.getItem("address");
        const connectInfoResult = connectInfo(storeState());

        if (EQwalletAddress || connectInfoResult.address) {
          return EQwalletAddress || connectInfoResult.address;
        } else {
          if (connectWalletAddress) {
            return connectWalletAddress;
          }
          throw new Error("walletAddress_does_not_exist");
        }
      } catch {
        throw new Error("walletAddress_get_fail");
      }
    });
  },

  getConnectWalletData: () => {
    return actionController(async () => {
      try {
        const accountId = await wallet.sessionStorage.getConnectedWalletId();

        const walletType = await wallet.sessionStorage.getConnectedWalletType();
        if (!walletType) {
          throw new Error("walletType_does_not_exist");
        }

        const connectWalletAddress = await wallet.sessionStorage.getAddress();
        const EQwalletAddress = sessionStorage.getItem("address");
        const connectInfoResult = connectInfo(storeState());

        if (EQwalletAddress || connectInfoResult.address) {
          const walletData = {
            connectWalletType: walletType,
            connectAddress: EQwalletAddress || connectInfoResult.address,
            accountId: accountId ? accountId : null,
          };
          return walletData;
        } else {
          if (connectWalletAddress) {
            const walletData = {
              connectWalletType: walletType,
              connectAddress: connectWalletAddress,
              accountId: accountId ? accountId : null,
            };
            return walletData;
          }
          throw new Error("walletAddress_does_not_exist");
        }
      } catch {
        throw new Error("walletAddress_get_fail");
      }
    });
  },

  setConnectAddress: (address: string) => {
    return actionController(async () => {
      try {
        const connectAddress = await wallet.sessionStorage.saveAddress(address);
        return connectAddress;
      } catch (error) {
        throw new Error("address_save_fail");
      }
    });
  },
};
