import { Route, Routes } from 'react-router-dom';
import CreateToken from './token/page/CreateToken';
import ManageToken from './token/page/ManageToken';
import MintToken from './token/page/MintToken';
import BurnToken from './token/page/BurnToken';
import CreateAirdrop from './airdrop/page/CreateAirdrop';
import AirdropList from './airdrop/page/AirdropList';
import ManageAirdrop from './airdrop/page/ManageAirdrop';
import EditAirdrop from './airdrop/page/EditAirdrop';
import NFTProjectList from './nft/page/NFTProjectList';
import CreateNFTProject from './nft/page/CreateNFTProject';
import ManageNFTProject from './nft/page/ManageNFTProject';
import EditNFTProject from './nft/page/EditNFTProject';
import MintNFT from './nft/page/MintNFT';
import ManageNFT from './nft/page/ManageNFT';
import EditToken from './token/page/EditToken';
import TokenKitList from '../kits/token/TokenKitList';
import ManageTokenKit from '../kits/token/ManageTokenKit';
import CreateTokenKit from '../kits/token/CreateTokenKit';

const ApplicationRouter = () => {
  return (
    <Routes>
      <Route path={'token-kit/*'} element={<TokenKitRouter />} />
      <Route path={'token/*'} element={<TokenRouter />} />
      <Route path={'airdrop/*'} element={<AirdropRouter />} />
      {/* <Route path={"launchpad/*"} element={<LaunchpadRouter />} /> */}
      <Route path={'nft/*'} element={<NFTRouter />} />
    </Routes>
  );
};

export default ApplicationRouter;

const TokenKitRouter = () => {
  return (
    <Routes>
      <Route index element={<TokenKitList />} />
      <Route path="create" element={<CreateTokenKit />} />
      <Route path={':id/*'} element={<ManageTokenKit />} />
    </Routes>
  );
};

const TokenRouter = () => {
  return (
    <Routes>
      <Route index element={<TokenKitList />} />
      {/*<Route path="create" element={<CreateToken />} />*/}
      <Route path="manage/*">
        <Route path={':id/*'}>
          <Route index element={<ManageToken />} />
          <Route path={'edit'} element={<EditToken />} />
          <Route path={'mint'} element={<MintToken />} />
          <Route path={'burn'} element={<BurnToken />} />
        </Route>
      </Route>
    </Routes>
  );
};

const NFTRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<CreateNFTProject />} />
      <Route path="manage/*">
        <Route index element={<NFTProjectList />} />
        <Route path={':id/*'}>
          <Route index element={<ManageNFTProject />} />
          <Route path={'edit'} element={<EditNFTProject />} />
          <Route path={'mint'} element={<MintNFT />} />
          <Route path={':tokenId'} element={<ManageNFT />} />
        </Route>
      </Route>
    </Routes>
  );
};

const AirdropRouter = () => {
  return (
    <Routes>
      <Route path="create/*" element={<CreateAirdrop />} />
      <Route path="manage/*">
        <Route index element={<AirdropList />} />
        <Route path={':id/*'}>
          <Route index element={<ManageAirdrop />} />
          <Route path={'edit'} element={<EditAirdrop />} />
        </Route>
      </Route>
    </Routes>
  );
};

const LaunchpadRouter = () => {
  return (
    <Routes>
      {/* <Route path="create" element={<Creelaunchpad />}/>
      <Route path="manage/*">
        <Route index element={<>launchpad list< />}/>
        <Route path={"pre/:id"} element={<>manage sale< />}/>
        <Route path={"public/:id"} element={<>manage sale< />}/>
      </Route> */}
    </Routes>
  );
};
