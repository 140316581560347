import { logic } from '../../../logic';
import { errorAlert } from '../../../utils/Utils';
import { ConnectedWallet } from './kit.type';

const TRANSFER_FUNCTION_METHODS = [
  {
    // balanceOf
    method_id: 101,
    inputs: [{ name: 'account', placeholder: 'Account address to verify balance' }],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
      }
    ) => {
      if (!option.kitId || !inputValue.account) {
        console.log('kitId is required');
        return;
      }
      const { result, error } = await logic.tokenKit.getTokenBalanceOf(option.kitId, inputValue.account);
      if (error) {
        errorAlert('Failed to execute function. Check your parameter or try later');
        return;
      }
      return result;
    },
  },
  {
    // transfer
    method_id: 102,
    inputs: [
      { name: 'to', placeholder: 'Account address that receive token' },
      { name: 'amount', placeholder: 'Amount of transferring token' },
    ],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
        connectedWallet?: ConnectedWallet;
        password?: string;
      }
    ) => {
      if (!inputValue.to || !inputValue.amount || !option.microChainId || !option.contractAddress || !option.kitId) {
        console.log('kitId, to, amount is required');
        return;
      }
      const { connectedWallet } = option;
      const { connectWalletType: walletType } = connectedWallet || {};
      if (walletType === 'metamask') {
        const { result, error } = await logic.tokenKit.tokenKitTransferWithMetamask(
          inputValue.amount,
          option.microChainId,
          option.contractAddress,
          inputValue.to
        );
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later');
          return;
        }
        return result;
      }
      if (walletType === 'accountManager') {
        if (!option.password || option.password.length > 6) {
          console.log('password is required');
          return;
        }
        const { result, error } = await logic.tokenKit.tokenKitTransferWithServer(option.kitId, option.password, [
          {
            amount: inputValue.amount as number,
            recipientAddress: inputValue.to as string,
          },
        ]);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later');
          return;
        }
        return result;
      }
    },
  },
  {
    // transferFrom
    method_id: 103,
    inputs: [
      { name: 'from', placeholder: 'Account address to transfer token' },
      { name: 'to', placeholder: 'Account address to receive token' },
      { name: 'amount', placeholder: 'Amount of transferring token' },
    ],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
        connectedWallet?: ConnectedWallet;
        password?: string;
      }
    ) => {
      if (!inputValue.from || !inputValue.to || !inputValue.amount || !option.microChainId || !option.contractAddress || !option.kitId) {
        console.log('from, to, amount is required');
        return;
      }
      const { connectedWallet } = option;
      const { connectWalletType: walletType } = connectedWallet || {};
      if (walletType === 'metamask') {
        const { result, error } = await logic.tokenKit.tokenKitTransferFromWithMetamask(
          inputValue.amount,
          option.microChainId,
          option.contractAddress,
          inputValue.to,
          inputValue.from
        );
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later');
          return;
        }
        return result;
      }
      if (walletType === 'accountManager') {
        if (!option.password || option.password.length > 6) {
          console.log('password is required');
          return;
        }
        const { result, error } = await logic.tokenKit.tokenKitTransferWithServer(option.kitId, option.password, [
          {
            amount: inputValue.amount as number,
            recipientAddress: inputValue.to as string,
            option: {
              senderAddress: inputValue.from as string,
            },
          },
        ]);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later');
          return;
        }
        return result;
      }
    },
  },
  {
    // multiTransfer
    method_id: 104,
    inputs: [
      { name: 'to', placeholder: 'Account address that receive token' },
      { name: 'amount', placeholder: 'Amount of transferring token' },
    ],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
        connectedWallet?: ConnectedWallet;
        password?: string;
      }
    ) => {
      if (!inputValue || !option.microChainId || !option.contractAddress || !option.kitId) {
        console.log('to, amount is required');
        return;
      }
      const { connectedWallet } = option;
      const { connectWalletType: walletType } = connectedWallet || {};
      if (walletType === 'metamask') {
        const { result, error } = await logic.tokenKit.tokenKitMultiTransferWithMetamask(inputValue, option.microChainId, option.contractAddress);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
      if (walletType === 'accountManager') {
        if (!option.password || option.password.length > 6) {
          console.log('password is required');
          return;
        }
        const transferDatas = inputValue.map((item: any) => {
          return {
            amount: item.amount,
            recipientAddress: item.to,
          };
        });
        const { result, error } = await logic.tokenKit.tokenKitTransferWithServer(option.kitId, option.password, transferDatas);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
    },
  },
  {
    // transferHistory
    method_id: 105,
    inputs: [{ name: 'address', placeholder: 'Account address to view transfer history' }],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
      },
      lastId?: number
    ) => {
      if (!option.kitId || !inputValue.address) {
        console.log('kitId, address is required');
        return;
      }
      const { result, error } = await logic.tokenKit.getTokenTransferHistory(option.kitId, inputValue.address, {
        limit: 10,
        lastId,
      });
      if (error) {
        errorAlert('Failed to execute function. Check your parameter or try later ');
        return;
      }
      const parsedDateResult = result.map((item: any) => {
        return {
          ...item,
          timestamp: new Date(item.timestamp).toLocaleString('en-KR'),
        };
      });
      return parsedDateResult;
    },
  },
];
const MINT_FUNCTION_METHODS = [
  {
    // balanceOf
    method_id: 201,
    inputs: [{ name: 'account', placeholder: 'Account address to verify balance' }],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
      }
    ) => {
      if (!option.kitId || !inputValue.account) {
        console.log('kitId is required');
        return;
      }
      const { result, error } = await logic.tokenKit.getTokenBalanceOf(option.kitId, inputValue.account);
      if (error) {
        errorAlert('Failed to execute function. Check your parameter or try later');
        return;
      }
      return result;
    },
  },
  {
    // totalSupply
    method_id: 202,
    inputs: [],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
      }
    ) => {
      if (!option.kitId) {
        console.log('kitId is required');
        return;
      }
      const { result, error } = await logic.tokenKit.getTokenTotalSupply(option.kitId);
      if (error) {
        errorAlert('Failed to execute function. Check your parameter or try later');
        return;
      }
      return result;
    },
  },
  {
    // mint
    method_id: 203,
    inputs: [
      { name: 'to', placeholder: 'Account address of issuing token' },
      { name: 'amount', placeholder: 'Amount of issuing token' },
    ],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
        connectedWallet?: ConnectedWallet;
        password?: string;
      }
    ) => {
      if (!inputValue.to || !inputValue.amount || !option.microChainId || !option.contractAddress || !option.kitId) {
        console.log('to, amount is required');
        return;
      }
      const { connectedWallet } = option;
      const { connectWalletType: walletType } = connectedWallet || {};
      if (walletType === 'metamask') {
        const { result, error } = await logic.tokenKit.tokenKitMintWithMetamask(inputValue.amount, inputValue.to, option.microChainId, option.contractAddress);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
      if (walletType === 'accountManager') {
        if (!option.password || option.password.length > 6) {
          console.log('password is required');
          return;
        }
        const { result, error } = await logic.tokenKit.tokenKitIssuancesWithServer(option.kitId, option.password, [
          {
            amount: inputValue.amount as number,
            option: {
              recipientAddress: inputValue.to as string,
            },
          },
        ]);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
    },
  },
  {
    // multiMint
    method_id: 204,
    inputs: [
      { name: 'to', placeholder: 'Account address of issuing token' },
      { name: 'amount', placeholder: 'Amount of issuing token' },
    ],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
        connectedWallet?: ConnectedWallet;
        password?: string;
      }
    ) => {
      if (!inputValue || !option.microChainId || !option.contractAddress || !option.kitId) {
        console.log('to, amount is required');
        return;
      }
      const { connectedWallet } = option;
      const { connectWalletType: walletType } = connectedWallet || {};
      if (walletType === 'metamask') {
        const { result, error } = await logic.tokenKit.tokenKitMultiMintWithMetamask(inputValue, option.microChainId, option.contractAddress);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
      if (walletType === 'accountManager') {
        if (!option.password || option.password.length > 6) {
          console.log('password is required');
          return;
        }
        const transferDatas = inputValue.map((item: any) => {
          return {
            amount: item.amount,
            recipientAddress: item.to,
          };
        });
        const { result, error } = await logic.tokenKit.tokenKitIssuancesWithServer(option.kitId, option.password, transferDatas);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
    },
  },
];
const BURN_FUNCTION_METHODS = [
  {
    // balanceOf
    method_id: 301,
    inputs: [{ name: 'account', placeholder: 'Account address to verify balance' }],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
      }
    ) => {
      if (!option.kitId || !inputValue.account) {
        console.log('kitId is required');
        return;
      }
      const { result, error } = await logic.tokenKit.getTokenBalanceOf(option.kitId, inputValue.account);
      if (error) {
        errorAlert('Failed to execute function. Check your parameter or try later');
        return;
      }
      return result;
    },
  },
  {
    // burn
    method_id: 302,
    inputs: [{ name: 'amount', placeholder: 'Amount of token to burn' }],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
        connectedWallet?: ConnectedWallet;
        password?: string;
      }
    ) => {
      if (!inputValue.amount || !option.microChainId || !option.contractAddress || !option.kitId) {
        console.log('amount is required');
        return;
      }
      const { connectedWallet } = option;
      const { connectWalletType: walletType } = connectedWallet || {};
      if (walletType === 'metamask') {
        const { result, error } = await logic.tokenKit.tokenKitBurnWithMetamask(inputValue.amount, option.microChainId, option.contractAddress);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
      if (walletType === 'accountManager') {
        if (!option.password || option.password.length > 6) {
          console.log('password is required');
          return;
        }
        const { result, error } = await logic.tokenKit.tokenKitRedeemWithServer(option.kitId, inputValue.amount, option.password);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
    },
  },
  {
    // burnFrom
    method_id: 303,
    inputs: [
      { name: 'from', placeholder: 'Account address to burn token' },
      { name: 'amount', placeholder: 'Amount of token to burn' },
    ],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
        connectedWallet?: ConnectedWallet;
        password?: string;
      }
    ) => {
      if (!inputValue.from || !inputValue.amount || !option.microChainId || !option.contractAddress || !option.kitId) {
        console.log('from, amount is required');
        return;
      }
      const { connectedWallet } = option;
      const { connectWalletType: walletType } = connectedWallet || {};
      if (walletType === 'metamask') {
        const { result, error } = await logic.tokenKit.tokenKitBurnFromWithMetamask(
          inputValue.amount,
          inputValue.from,
          option.microChainId,
          option.contractAddress
        );
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
      if (walletType === 'accountManager') {
        if (!option.password || option.password.length > 6) {
          console.log('password is required');
          return;
        }
        const { result, error } = await logic.tokenKit.tokenKitRedeemWithServer(option.kitId, inputValue.amount, option.password, {
          redeemingAddress: inputValue.from as string,
        });
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later ');
          return;
        }
        return result;
      }
    },
  },
];
const APPROVAL_FUNCTION_METHODS = [
  {
    // approve
    method_id: 401,
    inputs: [
      { name: 'spender', placeholder: 'Account address that transfer token instead of token owner' },
      { name: 'amount', placeholder: 'Amount of token to approve' },
    ],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
        connectedWallet?: ConnectedWallet;
        password?: string;
      }
    ) => {
      if (!inputValue.spender || !inputValue.amount || !option.microChainId || !option.contractAddress || !option.kitId) {
        console.log('spender, amount is required');
        return;
      }
      const { connectedWallet } = option;
      const { connectWalletType: walletType } = connectedWallet || {};
      if (walletType === 'metamask') {
        const { result, error } = await logic.tokenKit.tokenKitApproveTransactionWithMetamask(
          inputValue.amount,
          option.microChainId,
          option.contractAddress,
          inputValue.spender
        );
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later');
          return;
        }
        return result;
      }
      if (walletType === 'accountManager') {
        if (!option.password || option.password.length > 6) {
          console.log('password is required');
          return;
        }
        const { result, error } = await logic.tokenKit.tokenKitApproveWithServer(option.kitId, inputValue.amount, option.password, inputValue.spender);
        if (error) {
          errorAlert('Failed to execute function. Check your parameter or try later');
          return;
        }
        return result;
      }
    },
  },
  {
    // allowance
    method_id: 402,
    inputs: [
      { name: 'owner', placeholder: 'Account address that owns token' },
      { name: 'spender', placeholder: 'Account address that transfer token instead of token owner' },
    ],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
      }
    ) => {
      if (!option.kitId || !inputValue.owner || !inputValue.spender) {
        console.log('kitId, owner, spender is required');
        return;
      }
      const { result, error } = await logic.tokenKit.getTokenAllowanceHistory(option.kitId, inputValue.owner, inputValue.spender);
      if (error) {
        errorAlert('Failed to execute function. Check your parameter or try later ');
        return;
      }
      return result;
    },
  },
  {
    // approvalHistory
    method_id: 403,
    inputs: [{ name: 'address', placeholder: 'Account address to view approval history' }],
    onClick: async (
      inputValue: any,
      option: {
        kitId?: number;
        microChainId?: number;
        contractAddress?: string;
      },
      lastId?: number
    ) => {
      if (!option.kitId || !inputValue.address) {
        console.log('kitId, address is required');
        return;
      }
      const { result, error } = await logic.tokenKit.getTokenApprovalsHistory(option.kitId, inputValue.address, {
        limit: 10,
        lastId,
      });
      if (error) {
        errorAlert('Failed to execute function. Check your parameter or try later ');
        return;
      }
      const parsedDateResult = result.map((item: any) => {
        return {
          ...item,
          timestamp: new Date(item.timestamp).toLocaleString(),
        };
      });
      return parsedDateResult;
    },
  },
];

export const TOKEN_KIT_FUNCTION_METHOD = {
  1: TRANSFER_FUNCTION_METHODS,
  2: MINT_FUNCTION_METHODS,
  3: BURN_FUNCTION_METHODS,
  4: APPROVAL_FUNCTION_METHODS,
};
