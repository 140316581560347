import React, { useState } from 'react';
import './ResetPassword.scss';
import invalidIcon from '../../../../assets/images/Image_invalid.png';
import logo from '../../../../assets/images/signup_logo_EQHub.png';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import qs, { ParsedQs } from 'qs';
import toast from 'react-hot-toast';
import PageLoading from '../../../../components/_atom/loading/PageLoading';
import { errorAlert } from '../../../../../utils/Utils';
import { changeUserPasswordAction, verifyFindPasswordAuthCodeAction } from '../../../../../action/userAction';
import DivideLine from 'view/components/_atom/line/DivideLine';
import useValidateCondition from '../../../../../hooks/useValidateCondition';
import { VALIDATION__USER_newPassword } from 'view/service/user/validation/userValidation';
import { VALIDATION__USER_confirmPassword } from 'view/service/user/validation/userValidation';
import { ColumnField, FieldLabel } from 'view/components/_atom/section/Field';
import CommentInput from '../../../../components/_atom/input/CommentInput';
import BasicButton from '../../../../components/_atom/button/BasicButton';
import { ButtonTheme } from 'view/components/_atom/button/StyledButton';
import PasswordCondition from 'view/components/_atom/box/PasswordCondition';
import useIsAble from '../../../../../hooks/useIsAble';
import ButtonContainer from '../../../../components/_atom/container/ButtonContainer';
import useLoading from 'hooks/useLoading';
import InitialNoticeTemplate from 'view/service/initialize/components/InitialNoticeTemplate';
import InitialContentTemplate from 'view/service/initialize/components/InitialContentTemplate';

const PASSWORD_CONDITION_ITEM = [
  `8 to 15 characters.`,
  `Includes uppercase letters, lowercase letters, numbers, and special characters.\n( Special characters : ~․!@#$%^&*()_-+=[]|\;:‘”<>,.?/ )`,
  `Does not include consecutive numbers, consecutive letters, or repeated letters, repeated numbers.\n( Ex. aaa, abc, qwe, 111, 123 )`,
  `The 4 most recently used passwords cannot be reused.`,
];

enum emailVerifyErrorType {
  BROWSER_MISMATCH = 'BROWSER_MISMATCH',
  CONFLICT = 'CONFLICT',
}

function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const { authCode } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [validStateStatus, setValidStateStatus] = useState<boolean>(false);
  const [verifyErrorType, setVerifyErrorType] = useState<emailVerifyErrorType>();

  const verifyFindPasswordAuthCode_ = async () => {
    const { result, error } = await verifyFindPasswordAuthCodeAction(authCode as string);
    if (result) {
      setValidStateStatus(true);
    }
    if (error) {
      if (error.data.serverStatus === 26) {
        setVerifyErrorType(emailVerifyErrorType.BROWSER_MISMATCH);
        return;
      }
      if (error.data.serverStatus === 27) {
        setVerifyErrorType(emailVerifyErrorType.CONFLICT);
        return;
      }
      toast.error(error.data.message);
    }
  };

  const checkAuthCode = async () => {
    if (!!authCode) {
      await verifyFindPasswordAuthCode_();
    } else {
      toast.error('Invalid Access');
      navigate('/initialize');
    }
  };

  const { loading } = useLoading({
    dependency: [authCode],
    synchronousFunction: async () => {
      await checkAuthCode();
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      {validStateStatus && <ValidResetPassword authCode={authCode} navigate={navigate} />}
      {!validStateStatus && <InvalidResetPassword verifyErrorType={verifyErrorType} navigate={navigate} />}
    </>
  );
}

export default ResetPassword;

type ValidResetPasswordProps = {
  authCode: any;
  navigate: NavigateFunction;
};
const ValidResetPassword = (props: ValidResetPasswordProps) => {
  const { authCode, navigate } = props;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const passwordValidation = useValidateCondition([password], VALIDATION__USER_newPassword(password));
  const confirmPasswordValidation = useValidateCondition([password, confirmPassword], VALIDATION__USER_confirmPassword(password, confirmPassword));

  const changePassword = async () => {
    const { result, error } = await changeUserPasswordAction(authCode as string, password);
    if (result) {
      toast.success('Password has been changed!');
      navigate('/');
    }
    if (error) {
      errorAlert(error.data.message);
    }
  };

  const isAble = useIsAble([passwordValidation.isValid, confirmPasswordValidation.isValid]);

  return (
    <div className="reset-password-container">
      <img className="reset-password-logo" src={logo} alt="" />

      <InitialContentTemplate title="Reset Password" description={`Use 8 or more characters with a mix of\nletters and numbers`}>
        <div className="reset-password-body">
          <ColumnField>
            <FieldLabel label="Password" />
            <CommentInput
              {...passwordValidation}
              type="password"
              placeholder={'Use 8 to 15 characters including numbers and special characters'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </ColumnField>
          <ColumnField>
            <FieldLabel label="Confirm Password" />
            <CommentInput
              {...confirmPasswordValidation}
              placeholder={'Use 8 to 15 characters including numbers and special characters'}
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </ColumnField>
          <div className="reset-password-body-password-condition">
            <PasswordCondition passwordConditionItems={PASSWORD_CONDITION_ITEM} />
          </div>
        </div>

        <div className="reset-password-button-container">
          <ButtonContainer>
            <BasicButton theme={ButtonTheme.SECONDARY} onClick={() => navigate('/initialize')}>
              Cancel
            </BasicButton>
            <BasicButton disabled={!isAble} onClick={() => changePassword()}>
              Change Password
            </BasicButton>
          </ButtonContainer>
        </div>
      </InitialContentTemplate>
    </div>
  );
};

type InvalidResetPasswordProps = {
  verifyErrorType: emailVerifyErrorType | undefined;
  navigate: NavigateFunction;
};

const InvalidResetPassword = (props: InvalidResetPasswordProps) => {
  const { verifyErrorType, navigate } = props;

  return (
    <div className="reset-password-invalid-container">
      <img className="reset-password-invalid-logo" src={logo} alt="" />

      <InitialNoticeTemplate image={invalidIcon} title="Reset password">
        <div className="reset-password-invalid-content-description">
          {verifyErrorType === emailVerifyErrorType.BROWSER_MISMATCH && <span>{`Browser is not match.\nPlease proceed with the matching browser again.`}</span>}
          {verifyErrorType === emailVerifyErrorType.CONFLICT && (
            <span>{`The link in the email has expired.\nPlease try the password reset process again.`}</span>
          )}
        </div>
        <div className="reset-password-invalid-button-container">
          <ButtonContainer>
            <BasicButton onClick={() => navigate('/initialize')}>Done</BasicButton>
          </ButtonContainer>
        </div>
      </InitialNoticeTemplate>
    </div>
  );
};
