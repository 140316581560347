export const WalletSessionStorage = {
  saveAddress: async (address: string) => {
    try {
      return await sessionStorage.setItem("walletAddress", address);
    } catch (e) {
      throw e;
    }
  },
  getAddress: async () => {
    try {
      return await sessionStorage.getItem("walletAddress");
    } catch (e) {
      throw e;
    }
  },
  removeAddress: async () => {
    try {
      return await sessionStorage.removeItem("walletAddress");
    } catch (e) {
      throw e;
    }
  },
  setConnectedWalletType: async (walletType: string) => {
    try {
      await sessionStorage.setItem("connectedWalletType", walletType);
      return true;
    } catch (e) {
      throw e;
    }
  },
  getConnectedWalletType: async () => {
    try {
      return await sessionStorage.getItem("connectedWalletType");
    } catch (e) {
      throw e;
    }
  },
  deleteConnectedWalletType: async () => {
    try {
      return await sessionStorage.removeItem("connectedWalletType");
    } catch (e) {
      throw e;
    }
  },
  setConnectedWalletId: async (id: string) => {
    try {
      await sessionStorage.setItem("connectedWalletId", id);
      return true;
    } catch (e) {
      throw e;
    }
  },
  getConnectedWalletId: async () => {
    try {
      return await sessionStorage.getItem("connectedWalletId");
    } catch (e) {
      throw e;
    }
  },
  removeConnectedWalletId: async () => {
    try {
      return await sessionStorage.removeItem("connectedWalletId");
    } catch (e) {
      throw e;
    }
  },
};
