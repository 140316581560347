import { accountManagerController } from "./\bAccountManager/AccountmanagerController";
import { contractController } from "./Contract/ContractController";
import { currencyController } from "./Currency/CurrencyController";
import { microChainController } from "./MicroChain/MicroChainController";
import { projectController } from "./Project/ProjectController";
import { requestController } from "./Request/RequestController";
import { tokenKitsController } from "./Token-Kits/TokenKitsController";
import { walletController } from "./Wallet/WalletController";

export const controllers = {
  microChain: microChainController,
  wallet: walletController,
  request: requestController,
  tokenKits: tokenKitsController,
  currency: currencyController,
  contract: contractController,
  project: projectController,
  accountManager: accountManagerController,
};
