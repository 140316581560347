import React, { useEffect, useState } from 'react';
import PageArticle from '../../../components/_template/page/PageArticle';
import PageTitle from '../../../components/_molecules/page/PageTitle';
import { FieldLabel } from '../../../components/_atom/section/Field';
import CurrencyImageUploadModal from '../../../components/_modal/CurrencyImageUploadModal';
import KitImageUpload from '../../../components/_atom/file/KitImageUpload';
import KitContainer from '../components/KitContainer';
import DivideLine from '../../../components/_atom/line/DivideLine';
import Select from '../../../components/_atom/select/Select';
import CommentInput from '../../../components/_atom/input/CommentInput';
import BasicButton from '../../../components/_atom/button/BasicButton';
import { ButtonSize } from '../../../components/_atom/button/StyledButton';
import { logic } from '../../../../logic';
import { TokenKitType } from '../../../assets/constant/tokenKit.type';
import Checkbox from '../../../components/_atom/checkbox/Checkbox';
import { errorAlert, networkParserNew, successAlert } from '../../../../utils/Utils';
import { useNavigate } from 'react-router-dom';
import { ConnectedWallet, KitFunction } from '../../../assets/constant/kit.type';
import ConnectWalletModal from '../components/ConnectWalletModal';

const CreateTokenKit = () => {
  const navigate = useNavigate();
  const [kitImageModalOpen, setKitImageModalOpen] = useState(false);
  const getTokenKitTypes = async () => {
    const { result, error } = await logic.tokenKit.getTokenKitTypeList();
    if (error) {
      return;
    }
    setKitTypes(result);
  };
  useEffect(() => {
    getTokenKitTypes();
  }, []);

  const [kitName, setKitName] = useState('');
  const [kitSymbol, setKitSymbol] = useState('');
  const [kitImage, setKitImage] = useState('');
  const [kitTypes, setKitTypes] = useState<TokenKitType[]>([]);
  const [selectedKitTypeId, setSelectedKitTypeId] = useState<number>();
  const [kitContractOwnerAddress, setKitContractOwnerAddress] = useState('');
  const [isPublish, setIsPublish] = useState(false);

  const createTokenKit = async () => {
    const { result, error } = await logic.tokenKit.createTokenKit(1, isPublish, kitName.trim(), kitSymbol.trim(), kitImage, kitContractOwnerAddress);
    if (error) {
      if (error.data === 'tokenKit_create_fail') {
        errorAlert('Failed to create token kit. Please try again later.');
      }
      if (error.data === 'tokenKit_upload_image_fail') {
        errorAlert('Failed to upload token kit image. Please try again later.');
      }
      errorAlert('Failed to create token kit. Please try again later.');
      return;
    }
    if (result) {
      successAlert({
        title: 'New Token Kit is ready to use!',
        message: 'Deploy now to get started with new token kit.',
        buttonText: 'Got It!',
      });
      navigate('/application/token-kit');
    }
  };
  const canCreate = () => {
    if (!kitName || !kitSymbol || !kitImage || !selectedKitTypeId || !kitContractOwnerAddress) {
      return false;
    }
    return true;
  };

  const [kitFunctions, setKitFunctions] = useState<KitFunction[]>([]);
  const getKitFunctions = async (kit_type_id: number) => {
    const { result, error } = await logic.tokenKit.getTokenFunctionListWithKitTypeId(kit_type_id);
    if (error) {
      return;
    }
    setKitFunctions(result);
  };
  const [supportedChains, setSupportedChains] = useState<any[]>([]);
  const getSupportedChains = async (kit_type_id: number) => {
    const { result, error } = await logic.tokenKit.getSupportedChainListWithKitTypeId(kit_type_id);
    if (error) {
      return;
    }
    const { result: microChains, error: microChainsError } = await logic.microChain.getMicroChainList({});
    if (microChainsError) {
      return;
    }
    const networks = networkParserNew(microChains);
    const supportedChains = result.map((chain) => {
      const network = networks.find((network) => network.id === chain.chain_id);
      return network;
    });
    setSupportedChains(supportedChains);
  };

  useEffect(() => {
    if (selectedKitTypeId) {
      getKitFunctions(selectedKitTypeId);
      getSupportedChains(selectedKitTypeId);
    }
  }, [selectedKitTypeId]);

  const [connectedWallet, setConnectedWallet] = useState<ConnectedWallet>();
  useEffect(() => {
    setKitContractOwnerAddress(connectedWallet?.connectAddress || '');
  }, [connectedWallet]);
  const [isOpenConnectWalletModal, setIsOpenConnectWalletModal] = useState(false);
  return (
    <>
      {kitImageModalOpen && (
        <CurrencyImageUploadModal
          title={'Select Token Kit Image'}
          currencyImage={kitImage}
          setCurrencyImage={setKitImage}
          currencyImageModalOpen={kitImageModalOpen}
          setCurrencyImageModalOpen={setKitImageModalOpen}
        />
      )}
      <ConnectWalletModal isOpen={isOpenConnectWalletModal} closeModal={() => setIsOpenConnectWalletModal(false)} setConnectedWallet={setConnectedWallet} />
      <PageArticle id="CreateTokenKit">
        <PageTitle title={'Create Token Kit'} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: 40, width: '100%', padding: 40 }}>
          <KitContainer title="Basic Info">
            <DivideLine style={{ margin: '-10px 0 0' }} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '0 10px' }}>
              <FieldLabel label="Kit Image" essential={true} />
              <KitImageUpload
                image={kitImage}
                setImage={setKitImage}
                style={{ alignItems: 'start', paddingLeft: 10 }}
                previewStyle={{ borderRadius: '50%' }}
                buttonText={'Image select'}
                onClickUploader={() => setKitImageModalOpen(true)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '0 10px' }}>
              <FieldLabel label="Kit Name" essential={true} />
              <div style={{ paddingLeft: 10 }}>
                <CommentInput
                  value={kitName}
                  placeholder={'Ex) EQ Point'}
                  maxLength={50}
                  onChange={(e) => setKitName(e.target.value.trimStart())}
                  // {...tokenSymbolValidation}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '0 10px' }}>
              <FieldLabel label="Kit Symbol" essential={true} />
              <div style={{ paddingLeft: 10 }}>
                <CommentInput
                  value={kitSymbol}
                  placeholder={'Ex) EQP'}
                  onChange={(e) => setKitSymbol(e.target.value.trimStart().toUpperCase())}
                  // {...tokenSymbolValidation}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '0 10px' }}>
              <FieldLabel label="Kit Type" essential={true} />
              <div style={{ paddingLeft: 10 }}>
                <Select
                  labelKey={'name'}
                  valueKey={'kit_type_id'}
                  options={kitTypes}
                  selectedValue={selectedKitTypeId}
                  setSelectedValue={setSelectedKitTypeId}
                  canSelect={kitTypes.length !== 0}
                  placeholder={'Select a kit type'}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '0 10px' }}>
              <FieldLabel label="Dev Manager Wallet" essential={true} />
              <div style={{ display: 'flex', gap: 10, paddingLeft: 10 }}>
                <BasicButton size={ButtonSize.DYNAMIC} onClick={() => setIsOpenConnectWalletModal(true)}>
                  Import Wallet
                </BasicButton>
                <CommentInput
                  value={kitContractOwnerAddress}
                  placeholder={'Ex) 0x742d35Cc...'}
                  onChange={(e) => setKitContractOwnerAddress(e.target.value)}
                  // {...tokenSymbolValidation}
                />
              </div>
            </div>
          </KitContainer>
          <KitContainer title="Option">
            <DivideLine style={{ margin: '-10px 0 0' }} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '0 10px' }}>
              <FieldLabel label="Access" essential={false} />
              <div style={{ paddingLeft: 10 }}>
                <Checkbox id={'publish'} label={'Publish'} onChange={(e) => setIsPublish(e.target.checked)} checked={isPublish} />
              </div>
            </div>
          </KitContainer>
          {(kitFunctions.length !== 0 || supportedChains.length !== 0) && (
            <KitContainer title="Kit Info">
              <DivideLine style={{ margin: '-10px 0 0' }} />
              {kitFunctions.length !== 0 && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '0 10px' }}>
                  <FieldLabel label="Supported functions" essential={false} />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10, paddingLeft: 10 }}>
                    {kitFunctions.map((kitFunction) => (
                      <div style={{ whiteSpace: 'pre' }} key={kitFunction.feature_id}>
                        {'•  ' + kitFunction.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {supportedChains.length !== 0 && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: '0 10px' }}>
                  <FieldLabel label="Available blockchains" essential={false} />
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10, paddingLeft: 10 }}>
                    {supportedChains.map((chain) => (
                      <div style={{ whiteSpace: 'pre' }} key={chain.id}>
                        {'•  ' + chain.label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </KitContainer>
          )}
          <BasicButton size={ButtonSize.LARGE} onClick={() => createTokenKit()} style={{ alignSelf: 'flex-end' }} disabled={!canCreate()}>
            Create
          </BasicButton>
        </div>
      </PageArticle>
    </>
  );
};

export default CreateTokenKit;
