import React, { useEffect, useMemo, useState } from 'react';
import './Select.scss';
import IconSelectArrow from '../../../../view/assets/images/atom/icon-select-arrow.png';
import { useParams } from 'react-router-dom';

const Select = (props) => {
  const {
    placeholder = 'Select Value',
    options,
    selectedValue,
    setSelectedValue,
    labelKey = 'label',
    valueKey = 'value',
    disabled,
    canSelect = true,
    description,
    style,
    isAbsolute = true,
  } = props || {};

  const [isOptionPanelOpen, setIsOptionPanelOpen] = useState(false);

  useEffect(() => {
    function closeOptionPanel() {
      setIsOptionPanelOpen(false);
    }

    if (isOptionPanelOpen) window.addEventListener('click', closeOptionPanel);
    return () => {
      window.removeEventListener('click', closeOptionPanel);
    };
  }, [isOptionPanelOpen]);

  const canSelectStyle = useMemo(() => {
    return {
      color: '#b1b1c1',
      cursor: 'default',
    };
  }, [canSelect]);

  const isDisplayPlaceholder = useMemo(() => {
    return !(Boolean(selectedValue) || selectedValue === 0);
  }, [selectedValue]);

  return (
    <div className={'select'}>
      <button
        className="select-button"
        disabled={disabled}
        onClick={() => (canSelect ? setIsOptionPanelOpen((prev) => !prev) : undefined)}
        style={{ ...style, ...(!canSelect && { ...canSelectStyle }) }}
      >
        <div className={`select-value${isDisplayPlaceholder ? ' placeholder' : ''}`}>
          {isDisplayPlaceholder
            ? placeholder
            : options.find((el) => {
                if (valueKey === null) return el === selectedValue;
                return el[valueKey] === selectedValue;
              })[labelKey]}
        </div>
        {!disabled && <img className="select-arrow" src={IconSelectArrow} alt="" style={{ transform: isOptionPanelOpen && 'rotate(180deg)' }} />}
      </button>
      {Boolean(description) && <p className="select-description">{description}</p>}
      {isOptionPanelOpen && Array.isArray(options) && canSelect && (
        <div className="select-option-panel" style={isAbsolute ? {} : { position: 'relative', top: 0 }}>
          {options.map((el, index) => {
            const isSelected = valueKey === null ? el === selectedValue : el[valueKey] === selectedValue;
            return (
              <div
                key={index}
                className={`option-panel-label ${isSelected ? 'selected' : ''}`}
                onClick={() => {
                  if (valueKey === null) {
                    setSelectedValue(el);
                    return;
                  }
                  setSelectedValue(el[valueKey]);
                }}
              >
                {el[labelKey]}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Select;
