import { controllers } from "./controller";

class LogicPackage {
  constructor() {}

  wallet = {
    ...controllers.wallet,
  };

  microChain = {
    ...controllers.microChain,
  };
  request = {
    ...controllers.request,
  };
  tokenKit = {
    ...controllers.tokenKits,
  };
  currency = {
    ...controllers.currency,
  };
  contract = {
    ...controllers.contract,
  };
  project = {
    ...controllers.project,
  };
  accountManager = {
    ...controllers.accountManager,
  };
}

export const logic = new LogicPackage();
