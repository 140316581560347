import { v1ServerByProjectAuth } from "server/index/serverInstances";
import { makeQuery } from "server/index/utils";
import {
  createTokenKitRequest,
  createTokenKitResponse,
  getDefaultImageForTokenKitRequest,
  getDefaultImageForTokenKitResponse,
  getMyPublicTokenKitsRequest,
  getMyPublicTokenKitsResponse,
  getMyTokenKitsRequest,
  getMyTokenKitsResponse,
  getPublicTokenKitsRequest,
  getPublicTokenKitsResponse,
  getSupportedChainListRequest,
  getSupportedChainListResponse,
  getSupportedModuleContractListRequest,
  getSupportedModuleContractListResponse,
  getTokenAllowanceHistoryRequest,
  getTokenAllowanceHistoryResponse,
  getTokenApprovalsHistoryRequest,
  getTokenApprovalsHistoryResponse,
  getTokenBalanceOfRequest,
  getTokenBalanceOfResponse,
  getTokenFunctionListWithVersionIdRequest,
  getTokenFunctionListWithVersionIdResponse,
  getTokenFunctionRequest,
  getTokenFunctionResponse,
  getTokenKitDetailRequest,
  getTokenKitDetailResponse,
  getTokenKitTypeDetailRequest,
  getTokenKitTypeDetailResponse,
  getTokenKitTypeListRequest,
  getTokenKitTypeListResponse,
  getTokenKitTypeVersionListRequest,
  getTokenKitTypeVersionListResponse,
  getTokenKitVersionRequest,
  getTokenKitVersionResponse,
  getTokenTotalSupplyRequest,
  getTokenTotalSupplyResponse,
  getTokenTransferHistoryRequest,
  getTokenTransferHistoryResponse,
  postImageUploadForTokenKitRequest,
  postImageUploadForTokenKitResponse,
  postKitIdDeployResultRequest,
  postKitIdDeployResultResponse,
  postKitIdForMakeContractRequest,
  postKitIdForMakeContractResponse,
  postMyPublicTokenKitsRequest,
  postMyPublicTokenKitsResponse,
  tokenKitApproveWithServerRequest,
  tokenKitApproveWithServerResponse,
  tokenKitBurnWithServerRequest,
  tokenKitBurnWithServerResponse,
  tokenKitDeployWithServerRequest,
  tokenKitDeployWithServerResponse,
  tokenKitMintWithServerRequest,
  tokenKitMintWithServerResponse,
  transferWithServerRequest,
  transferWithServerResponse,
} from "./index.type";
//유저가 생성한 토큰키트 조회
const getMyTokenKits = async (
  request: getMyTokenKitsRequest
): Promise<getMyTokenKitsResponse> =>
  await v1ServerByProjectAuth.get({
    path: "/token-kits/kits/me",
    query: makeQuery(request.query),
  });

//ID Token 값을 검증하여 해당 projectId 로 추가된 Public Token Kit 목록을 조회
const getMyPublicTokenKits = async (
  request: getMyPublicTokenKitsRequest
): Promise<getMyPublicTokenKitsResponse> =>
  await v1ServerByProjectAuth.get({
    path: "/token-kits/kits/my-kits",
    query: makeQuery(request.query),
  });

//[Public Token Kit] 모달창 리스트의 목록에서 선택된 Public Token Kit를 추가
const postMyPublicTokenKits = async (
  request: postMyPublicTokenKitsRequest
): Promise<postMyPublicTokenKitsResponse> =>
  await v1ServerByProjectAuth.post({
    path: "/token-kits/kits/my-kits",
    query: makeQuery(request.query),
    data: request.data,
  });

//[Public Token Kit] 모달창 리스트의 목록을 조회합니다.
const getPublicTokenKits = async (
  request: getPublicTokenKitsRequest
): Promise<getPublicTokenKitsResponse> =>
  await v1ServerByProjectAuth.get({
    path: "/token-kits/kits/public",
    query: makeQuery(request.query),
  });

const getTokenKitDetail = async (
  request: getTokenKitDetailRequest
): Promise<getTokenKitDetailResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}`,
    query: makeQuery(request.query),
  });

const getTokenKitVersion = async (
  request: getTokenKitVersionRequest
): Promise<getTokenKitVersionResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/versions`,
    query: makeQuery(request.query),
  });

const getTokenTotalSupply = async (
  request: getTokenTotalSupplyRequest
): Promise<getTokenTotalSupplyResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/issuances/total-supply`,
    query: makeQuery(request.query),
  });

const getTokenFunctionList = async (
  request: getTokenFunctionRequest
): Promise<getTokenFunctionResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/features`,
    query: makeQuery(request.query),
  });

const getTokenFunctionListWithVersionId = async (
  request: getTokenFunctionListWithVersionIdRequest
): Promise<getTokenFunctionListWithVersionIdResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/versions/${request.params.versionId}/features`,
    query: makeQuery(request.query),
  });

const getTokenBalanceOf = async (
  request: getTokenBalanceOfRequest
): Promise<getTokenBalanceOfResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/ownerships/balance`,
    query: makeQuery(request.query),
  });

const getTokenTransferHistory = async (
  request: getTokenTransferHistoryRequest
): Promise<getTokenTransferHistoryResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/transfers/histories`,
    query: makeQuery(request.query),
  });

const getTokenApprovalsHistory = async (
  request: getTokenApprovalsHistoryRequest
): Promise<getTokenApprovalsHistoryResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/permissions/histories`,
    query: makeQuery(request.query),
  });

const getTokenAllowanceHistory = async (
  request: getTokenAllowanceHistoryRequest
): Promise<getTokenAllowanceHistoryResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/permissions/allowance`,
    query: makeQuery(request.query),
  });

const getSupportedChainList = async (
  request: getSupportedChainListRequest
): Promise<getSupportedChainListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/versions/${request.params.versionId}/supported-chains`,
    query: makeQuery(request.query),
  });

const getSupportedModuleContractList = async (
  request: getSupportedModuleContractListRequest
): Promise<getSupportedModuleContractListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/${request.params.kitId}/module-contracts`,
    query: makeQuery(request.query),
  });
//토큰 키트 타입 목록 조회
const getTokenKitTypeList = async (
  request: getTokenKitTypeListRequest
): Promise<getTokenKitTypeListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kit-types`,
    query: makeQuery(request.query),
  });

//키트타입 상세
const getTokenKitTypeDetail = async (
  request: getTokenKitTypeDetailRequest
): Promise<getTokenKitTypeDetailResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kit-types/${request.params.kitTypeId}`,
    query: makeQuery(request.query),
  });

//키트타입
const getTokenKitTypeVersionList = async (
  request: getTokenKitTypeVersionListRequest
): Promise<getTokenKitTypeVersionListResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kit-types/${request.params.kitTypeId}/versions`,
    query: makeQuery(request.query),
  });

const createTokenKit = async (
  request: createTokenKitRequest
): Promise<createTokenKitResponse> =>
  await v1ServerByProjectAuth.post({
    path: `token-kits/kits`,
    data: request.data,
    query: makeQuery(request.query),
  });

const getDefaultImageForTokenKit = async (
  request: getDefaultImageForTokenKitRequest
): Promise<getDefaultImageForTokenKitResponse> =>
  await v1ServerByProjectAuth.get({
    path: `/token-kits/kits/default-image`,
    query: makeQuery(request.query),
  });

const postImageUploadForTokenKit = async (
  request: postImageUploadForTokenKitRequest
): Promise<postImageUploadForTokenKitResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/upload-image`,
    query: makeQuery(request.query),
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

const postKitIdForMakeContract = async (
  request: postKitIdForMakeContractRequest
): Promise<postKitIdForMakeContractResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/${request.params.kitId}/submit`,
    data: request.data,
    query: makeQuery(request.query),
  });

const postKitIdDeployResult = async (
  request: postKitIdDeployResultRequest
): Promise<postKitIdDeployResultResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/${request.params.kitId}/deploy/notify`,
    query: makeQuery(request.query),
  });

/*******************************/
/********* Transfer 기능 ********/
/*******************************/

const tokenKitTransferWithServer = async (
  request: transferWithServerRequest
): Promise<transferWithServerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/${request.params.kitId}/transfers/transfer`,
    query: makeQuery(request.query),
    data: request.data,
  });

/*******************************/
/********* mint 기능 ************/
/*******************************/
const tokenKitMintWithServer = async (
  request: tokenKitMintWithServerRequest
): Promise<tokenKitMintWithServerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/${request.params.kitId}/issuances/issue`,
    query: makeQuery(request.query),
    data: request.data,
  });

/*******************************/
/********* burn 기능 ************/
/*******************************/

const tokenKitBurnWithServer = async (
  request: tokenKitBurnWithServerRequest
): Promise<tokenKitBurnWithServerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/${request.params.kitId}/issuances/redeem`,
    query: makeQuery(request.query),
    data: request.data,
  });

/*******************************/
/********* approve 기능 ************/
/*******************************/

const tokenKitApproveWithServer = async (
  request: tokenKitApproveWithServerRequest
): Promise<tokenKitApproveWithServerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/${request.params.kitId}/permissions/approve`,
    query: makeQuery(request.query),
    data: request.data,
  });

/*******************************/
/********* deploy 기능 ************/
/*******************************/

const tokenKitDeployWithServer = async (
  request: tokenKitDeployWithServerRequest
): Promise<tokenKitDeployWithServerResponse> =>
  await v1ServerByProjectAuth.post({
    path: `/token-kits/kits/${request.params.kitId}/deploy`,
    query: makeQuery(request.query),
    data: request.data,
  });

export {
  createTokenKit as createTokenKitFromServer,
  getDefaultImageForTokenKit as getDefaultImageForTokenKitFromServer,
  getMyPublicTokenKits as getMyPublicTokenKitsFromServer,
  getMyTokenKits as getMyTokenKitsFromServer,
  getPublicTokenKits as getPublicTokenKitsFromServer,
  getSupportedChainList as getSupportedChainListFromServer,
  getSupportedModuleContractList as getSupportedModuleContractListFromServer,
  getTokenAllowanceHistory as getTokenAllowanceHistoryFromServer,
  getTokenApprovalsHistory as getTokenApprovalsHistoryFromServer,
  getTokenBalanceOf as getTokenBalanceOfFromServer,
  getTokenFunctionList as getTokenFunctionListFromServer,
  getTokenFunctionListWithVersionId as getTokenFunctionListWithVersionIdFromServer,
  getTokenKitDetail as getTokenKitDetailFromServer,
  getTokenKitTypeDetail as getTokenKitTypeDetailFromServer,
  getTokenKitTypeList as getTokenKitTypeListFromServer,
  getTokenKitTypeVersionList as getTokenKitTypeVersionListFromServer,
  getTokenKitVersion as getTokenKitVersionFromServer,
  getTokenTotalSupply as getTokenTotalSupplyFromServer,
  getTokenTransferHistory as getTokenTransferHistoryFromServer,
  postImageUploadForTokenKit as postImageUploadForTokenKitFromServer,
  postKitIdDeployResult as postKitIdDeployResultFromServer,
  postKitIdForMakeContract as postKitIdForMakeContractFromServer,
  postMyPublicTokenKits as postMyPublicTokenKitsFromServer,
  tokenKitApproveWithServer as tokenKitApproveWithServerFromServer,
  tokenKitBurnWithServer as tokenKitBurnWithServerFromServer,
  tokenKitMintWithServer as tokenKitMintWithServerFromServer,
  tokenKitTransferWithServer as tokenKitTransferWithServerFromServer,
  tokenKitDeployWithServer as tokenKitDeployWithServerFromServer,
};
