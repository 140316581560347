import React from 'react';
import Skeleton from '../../../components/_atom/skeleton/Skeleton';
import './LabelValue.scss';

const LabelValue = ({
  label,
  description,
  value,
  isLoading,
}: {
  label: string;
  description?: string;
  value: string | React.ReactNode;
  isLoading?: boolean;
}) => {
  return (
    <div className="kit-label-value-wrapper">
      <div className="kit-label">{label}</div>
      {description && <div className="kit-label-description">{description}</div>}
      {isLoading && !value ? <Skeleton style={{ height: 30 }} /> : <div className="kit-value">{value === null || value === undefined ? '-' : value}</div>}
    </div>
  );
};

export default LabelValue;
