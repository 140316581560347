// const TARGET_EQHUB = 'eq-hub-extension'

import { logic } from "../../../logic";
import toast from "react-hot-toast";

const message = {
  event: "",
  payload: "",
};

export const isOurSource = (event) => event.source === window;

export const isRightForm = (event) => {
  if (!event.data) {
    return false;
  }
  return !!(event.data.event && event.data.payload);
};

export const getEvent = (event) => event.data.event;

export const requestTransactionToExtension = (payload) => {
  setTimeout(() => {
    const newMessage = { ...message };
    newMessage.event = "makeTransaction";
    newMessage.payload = payload;
    window.postMessage(newMessage, window.origin);
  }, 200);
};

export const requestAddressToMetamask = async (type) => {
  const { result: connectMetamaskResponse, error: connectMetamaskError } =
    await logic.wallet.connectWallet(type);
  console.log("connectMetamaskError", connectMetamaskError);
  if (connectMetamaskError) {
    toast.error("Please try again later");
    return;
  }
  if (connectMetamaskResponse) {
    toast.success("Connected Successfully!");
    return connectMetamaskResponse;
  }
};

export const requestAddressToExtension = () => {
  setTimeout(() => {
    const newMessage = { ...message };
    newMessage.event = "getAddress";
    newMessage.payload = {};
    window.postMessage(newMessage, window.origin);
  }, 200);
  return true;
};
